import React, {useState, useEffect} from 'react';
import {Star, StarBorderOutlined} from "@mui/icons-material";
import {Link} from "react-router-dom";

const SymbolFilter =
    ({filteredData, currentInterval}: { filteredData: any[], currentInterval:number }) => {
    const [storedItems, setStoredItems] = useState<string[]>(() => {
        // Initialisieren mit bereits gespeicherten Schlüsseln aus dem Local Storage
        return JSON.parse(localStorage.getItem('ChartRider.storedSymbols') || '[]');
    });

    const toggleStorage = (key: string) => {
        let updatedItems;

        if (storedItems.includes(key)) {
            // Wenn der Schlüssel bereits gespeichert ist, entfernen
            updatedItems = storedItems.filter(item => item !== key);
        } else {
            // Andernfalls, zum Speicher hinzufügen
            updatedItems = [...storedItems, key];
        }

        setStoredItems(updatedItems);

        // Local Storage aktualisieren
        localStorage.setItem('ChartRider.storedSymbols', JSON.stringify(updatedItems));
    };

    const markedItems = filteredData.filter((d: any) => storedItems.includes(`${d.exchange}${d.symbol}${d.category}`));
    const unmarkedItems = filteredData.filter((d: any) => !storedItems.includes(`${d.exchange}${d.symbol}${d.category}`));
    // Verwenden von useEffect, um storedItems initial zu laden und bei jeder Aktualisierung zu speichern
    useEffect(() => {
        localStorage.setItem('ChartRider.storedSymbols', JSON.stringify(storedItems));

    }, [storedItems]);

    return (
        <div className="flex flex-col w-full">
            {/* Markierte Liste */}
            <div className="p-0 overflow-y-scroll w-full custom-scrollbar max-h-[360px]">
                {markedItems.map((d: any) => {
                    const key = `${d.exchange}${d.symbol}${d.category}`;

                    return (
                        <div key={key} className="bg-black hover:bg-elementBackground shadow-md py-1 px-2 my-0 w-full hover:text-primaryHover text-white/50">
                            <div className="flex justify-between items-center w-full">
                                <Link className="font-100   w-full pt-1 flex"
                                      to={`/details/${d.exchange}/${d.symbol ? d.symbol : d.name}/${d.category}/${currentInterval}`}>
                                    <span className="flex-1 text-left overflow-hidden text-ellipsis whitespace-nowrap"
                                          title={d.symbol}>{d.symbol}</span>
                                    <span className="flex-1 text-center">{d.category}</span>
                                    <span className="flex-1 text-center">{d.exchange}</span>
                                </Link>
                                <span className="flex-1 text-center cursor-pointer" onClick={() => toggleStorage(key)}>
                                    <Star className="text-accent"/>
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>

            {/* Unmarkierte, scrollbare Liste */}
            <div className="p-0 overflow-y-scroll w-full max-h-screen custom-scrollbar">
                {unmarkedItems.map((d: any) => {
                    const key = `${d.exchange}${d.symbol}${d.category}`;
                    return (
                        <div key={key}
                             className="bg-background shadow-md py-1 px-2 my-0 w-full
                              hover:bg-elementBackground hover:text-primaryHover text-white/50
                             ">

                                <div className="flex justify-between items-center w-full">
                                    <Link className="font-100
                                     w-full pt-1 flex "
                                          to={`/details/${d.exchange}/${d.symbol ? d.symbol : d.name}/${d.category}/${currentInterval}`}>

                                <span className="flex-1 text-left overflow-hidden text-ellipsis whitespace-nowrap"
                                      title={d.symbol}>{d.symbol}</span>
                                    <span className="flex-1 text-center">{d.category}</span>
                                    <span className="flex-1 text-center">{d.exchange}</span>
                                    </Link>
                                    <span className="flex-1 text-center cursor-pointer text-white/50" onClick={() => toggleStorage(key)}>
                                    <StarBorderOutlined color="inherit"/>
                                </span>
                                </div>
                        </div>
                    );
                })}
            </div>
        </div>
    )
        ;
};

export default SymbolFilter;