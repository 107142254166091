import React, { useEffect, useState } from "react";

interface Strategy {
    strategyId: number;
    name: string;
}

interface StrategyData {
    strategyId: number;
    total: number;
    count: number;
    runAt: Date;
    averageInitialCapital: number;
    initialCapitalSum: number;
    finalCapitalSum: number;
    efficiencyValue: number;
    efficiencyRatio: number;
}

const DashboardStrategiesDetail: React.FC<Strategy> = ({ strategyId, name }) => {
    const [strategyData, setStrategyData] = useState<StrategyData | null>(null);

    useEffect(() => {
        const fetchStrategies = async () => {
            try {
                const url = process.env.REACT_APP_API_BASE_URL;

                const response = await fetch(url + '/strategy/' + strategyId);
                const strategyData = await response.json();
                if (strategyData.status === 'success') {
                    console.log(strategyData.data);
                    setStrategyData(strategyData.data);
                }
            } catch (error) {
                console.error('Error fetching strategies:', error);
            }
        };
        fetchStrategies();
    }, [strategyId]);

    return (
        <div className="my-6 mx-2 flex justify-start flex-col items-start bg-white bg-opacity-15 rounded-lg shadow-md p-6">
            <div className="pb-2 text-2xl text-white font-semibold">#{strategyId} {name}</div>
            <div className="w-full flex justify-between items-start">
                <table>
                    <tbody>
                    <tr>
                        <td className="pr-4">Paare getestet:</td>
                        <td className="font-bold">{strategyData?.count}</td>
                    </tr>
                    <tr>
                        <td className="pr-4">Gestartet am:</td>
                        <td className="font-bold">{strategyData?.runAt?.toString()}</td>
                    </tr>
                    <tr>
                        <td className="pr-4">Startkapital :</td>
                        <td className="font-bold">{strategyData?.averageInitialCapital}</td>
                    </tr>
                    <tr>
                        <td className="pr-4">Endkapital :</td>
                        <td className="font-bold">{strategyData?.efficiencyValue.toFixed(4)}</td>
                    </tr>
                    </tbody>
                </table>
                <table>
                    <tbody>
                    <tr>
                        <td className="pr-4">Gewinnrate:</td>
                        <td className="font-bold"></td>
                    </tr>
                    <tr>
                        <td className="pr-4">&nbsp;</td>
                        <td className="font-bold"></td>
                    </tr>
                    <tr>
                        <td className="pr-4">Gesamtes Startkapital:</td>
                        <td className="font-bold">{strategyData?.initialCapitalSum.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td className="pr-4">Gesamtes Endkapital:</td>
                        <td className="font-bold">{strategyData?.finalCapitalSum.toFixed(4)}</td>
                    </tr>
                    </tbody>
                </table>
                <div>
                    <EfficiencyRatioDisplay strategyData={strategyData} />
                </div>
            </div>
        </div>
    );
};

const EfficiencyRatioDisplay = ({ strategyData }: { strategyData: StrategyData | null }) => {
    const positiveClasses = "text-green-600 px-4 py-2 font-bold text-7xl";
    const negativeClasses = "text-red-600 px-4 py-2 font-bold text-7xl";

    return (
        <div  style={{ textShadow: 'black 0 0 15px' }} className={strategyData && strategyData.efficiencyRatio > 1 ? positiveClasses : negativeClasses}>
            {strategyData && strategyData.efficiencyRatio.toFixed(2)}&nbsp;x
        </div>
    );
};

export default DashboardStrategiesDetail;