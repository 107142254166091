import {KlineData} from "../../interfaces/interfaces";
import {ConfigStructure} from "../../types/indicator.config.ts";

export interface ResultData {
    [timestamp: number]: number | null;
}
//
export interface BoxedIndicatorConfig {
    period: number;
    color: string;
    height: number;
}

export default abstract class BoxedIndicator {
    protected data: KlineData[] = [];
    protected result: ResultData | null = null;
    // protected color: string = '#ffffff40';
    protected config: ConfigStructure;
    // public name: string = 'BoxedIndicator';
    // public search: string[] = [];

    constructor(inputConfig: ConfigStructure, id?:string) {
        this.config = inputConfig;
        this.config.className = this.constructor.name;
        this.config.id = id ? id : this.config.className + ':' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

    }

    public abstract isBoxed(): boolean;

    public abstract calculate(): ResultData | null;

    public abstract getResults(): ResultData | null ;

    public getResult(timestamp: number): number | null {
        return this.result ? this.result[timestamp] : null;
    }


    public getId() {
        return this.config.id;
    }

    public getConfig() {
        return this.config;
    }

    abstract setInputData(data: KlineData[]): void
    getLongestPeriod(): number | null {
        let maxValue: number | null = null;

        this.config.sections.forEach(section => {
            section.fields.forEach(field => {
                if (field.id === "period") {
                    if (typeof field.value === "number") {
                        if (maxValue === null || field.value > maxValue) {
                            maxValue = field.value;
                        }
                    }
                }
            });
        });
        return maxValue;
    }

    getColor(): string {
        let color: string = '';
        this.config.sections.forEach(section => {
            section.fields.forEach(field => {
                if (field.id === "color") {
                    if (typeof field.value === "string") {
                        color = field.value;
                    }
                }
            });
        });
        return color;
    }

    getPeriod(): number  {
        let period: number = 0;
        this.config.sections.forEach(section => {
            section.fields.forEach(field => {
                if (field.id === "period") {
                    if (typeof field.value === "number") {
                        period = field.value;

                    }
                }
            });
        });
        return period;
    }

    getHeight(): number  {
        let height: number = 0;
        this.config.sections.forEach(section => {
            section.fields.forEach(field => {
                if (field.id === "height") {
                    if (typeof field.value === "number") {
                        height = field.value;
                    }
                }
            });
        });
        return height;
    }

    getSearch(): string[] {
        return this.config.search;
    }

}