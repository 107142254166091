import {Indicator, IndicatorStorage} from "../interfaces/indicator.interface.ts";
import SimpleOverlayIndicator, {ResultData, SimpleOverlayIndicatorConfig} from "./base/SimpleOverlayIndicator.ts";
import {ConfigStructure} from "../types/indicator.config.ts";

export class MovingAverageIndicator extends SimpleOverlayIndicator implements Indicator {

    protected result: ResultData | null = null;

    constructor(inputConfig: IndicatorStorage, id?: string) {
        let defaultConfig: ConfigStructure = {
            id: id ? id : "",
            name: "Moving Average",
            className: "",
            shortName: "MA",
            search: ['MA', 'Moving Average'],
            sections: [
                {
                    id: "inputs",
                    title: "Inputs",
                    fields: [
                        {
                            id: "period",
                            label: "Period",
                            type: "number",
                            value: 21,
                            validations: [{
                                type: "minValue",
                                value: 1,
                                message: "Value must be greater or equal than 1"
                            }]
                        }
                    ]
                },
                {
                    id: "styles",
                    title: "Styles",
                    fields: [
                        {
                            id: "color",
                            label: "Line Color",
                            type: "color",
                            value: "#88ff88",
                        }
                    ]
                }
            ]
        }
        const conf = inputConfig ? inputConfig.config : defaultConfig;
        super(conf, id);

    }

    getResults(): ResultData | null {
        return this.result;
    }

    public calculate(): ResultData | null {
        this.result = null;
        const period = this.getPeriod();

        if (!period) return null;

        if (period && this.data.length < period) {
            return null;
        }

        const movingAverageResults: ResultData = {};

        for (let i = 0; i <= this.data.length - period; i++) {
            let sum = 0;
            for (let j = 0; j < period; j++) {
                sum += this.data[i + j].close;
            }
            const result = sum / period;
            movingAverageResults[this.data[i + period - 1].openTime] = result;
        }

        this.result = movingAverageResults;
        return movingAverageResults;
    }


}
