import {boxedIndicatorSlot, Layout} from "../../shared/interfaces/interfaces.ts";

class LayoutCalculator {
    private canvasMarginTop: number = 32;
    private canvasMarginRight: number = 48;
    private boxedIndicatorHeight: number = 0;
    private parentElementWidth: number;
    private parentElementHeight: number;
    private isOpen: boolean;
    private indicatorBoxes: boxedIndicatorSlot[] = [];

    constructor(screenSize?: { width: number, height: number }, isOpen?: boolean) {
        this.parentElementWidth = screenSize ? screenSize.width : document.body.offsetWidth - this.canvasMarginRight;
        this.parentElementHeight = screenSize ? screenSize.height : document.body.offsetHeight;
        this.isOpen = isOpen || false;
    }

    addBoxedIndicatorSlots(slot: boxedIndicatorSlot): void {
        this.indicatorBoxes.push(slot);
        this.boxedIndicatorHeight += slot.height;
    }

    getSymbolMenuWidth(): number {
        return 360;
    }

    getLayout(): Layout {

        let canvasWidth = this.parentElementWidth;
        let symbolMenuWidth = this.getSymbolMenuWidth();

        if (this.isOpen) {
            canvasWidth = this.parentElementWidth - symbolMenuWidth;
            symbolMenuWidth = this.getSymbolMenuWidth();
        } else {
            symbolMenuWidth = 0;
        }

        const browserLanguage = navigator.language;
        const downCandleInfoColor = 'rgb(154,93,93)';
        const upCandleInfoColor = "#446e53";
        const semiTransparentOverlayColor = "rgba(24,24,32,0.75)";
        const chartWatermarkColor = "rgb(28,28,32)";
        const canvasTotalHeight = this.parentElementHeight - this.canvasMarginTop;
        const zoomYScaleBoxWidth = 95;
        const dateLabelHeight = 32;
        const chartBoxHeight = canvasTotalHeight - dateLabelHeight - this.boxedIndicatorHeight;
        const chartBoxWidth = canvasWidth - zoomYScaleBoxWidth;

        return {
            parent: {
                width: this.parentElementWidth
            },
            canvas: {
                margin: {
                    top: this.canvasMarginTop,
                    right: this.canvasMarginRight,
                    bottom: 0,
                    left: 0
                },
                width: canvasWidth,
                height: canvasTotalHeight,
            },
            chartBox: {
                width: chartBoxWidth,
                height: chartBoxHeight,
                top: 0,
                right: chartBoxWidth,
                bottom: chartBoxHeight,
                left: 0,
                watermarkColor: chartWatermarkColor
            },
            symbolMenu: {
                width: symbolMenuWidth,
                height: canvasTotalHeight
            },
            zoomYScaleBox: {
                width: zoomYScaleBoxWidth,
                height: chartBoxHeight,
                top: 0,
                bottom: chartBoxHeight,
                left: canvasWidth - zoomYScaleBoxWidth,
                right: canvasWidth,
                color: semiTransparentOverlayColor
            },
            dateBarAreaBox: {
                height: dateLabelHeight,
                left: 0,
                top: chartBoxHeight,
                width: chartBoxWidth,
                color: semiTransparentOverlayColor
            },
            boxedIndicatorSlots: this.indicatorBoxes,
            boxedIndicatorArea: {
                height: this.boxedIndicatorHeight
            },
            candle: {
                downCandleInfoColor: downCandleInfoColor,
                upCandleInfoColor: upCandleInfoColor
            },
            dateLabelHeight: dateLabelHeight,
            inlinePadding: 5,
            browserLanguage: browserLanguage
        };
    }
}

export default LayoutCalculator;