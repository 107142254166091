import { Indicator, IndicatorStorage } from "../interfaces/indicator.interface.ts";
import { boxedIndicatorSlot, KlineData, Layout } from "../interfaces/interfaces";
import { ScaleLinear, ScaleTime, ZoomTransform } from "d3";
import BoxedIndicator from "./base/BoxedIndicator.ts";

export class OBVIndicator extends BoxedIndicator implements Indicator {
    protected result: { [key: number]: number } | null = null;
    public name: string = "On-Balance Volume";

    constructor(inputConfig: IndicatorStorage, id?: string) {
        const defaultConfig = {
            id: id ? id : "",
            name: "On-Balance Volume",
            className: "",
            shortName: "OBV",
            search: ['OBV', 'On-Balance Volume'],
            sections: [
                {
                    id: "inputs",
                    title: "Inputs",
                    fields: [
                        {
                            id: "height",
                            label: "Height",
                            type: "number",
                            value: 140,
                            validations: [{
                                type: "minValue",
                                value: 30,
                                message: "Value must be greater or equal than 30"
                            }]
                        }
                    ]
                },
                {
                    id: "styles",
                    title: "Styles",
                    fields: [
                        {
                            id: "color",
                            label: "Line Color",
                            type: "color",
                            value: "#ff6347",
                        }
                    ]
                }
            ]
        };

        const conf = inputConfig ? inputConfig.config : defaultConfig;

        super(conf, id);
    }

    public setInputData(data: KlineData[]) {
        this.data = data;
        this.clearCaches();
    }

    clearCaches() {
        this.result = null;
    }

    isBoxed() {
        return true;
    }

    getResults(): any {
        return this.result;
    }

    getSlot(slots: boxedIndicatorSlot[]): boxedIndicatorSlot {
        return slots.find(slot => slot.id === this.getId())!;
    }
    draw(visibleDataPoints: KlineData[], context: CanvasRenderingContext2D,
         xScale: ScaleTime<number, number>,
         yScale: ScaleLinear<number, number>,
         transform: ZoomTransform,
         layout: Layout
    ): void {
        const slot = this.getSlot(layout.boxedIndicatorSlots);
        const height = slot ? slot.height : this.getHeight();
        const width = slot ? slot.width : context.canvas.width;

        if (!slot) return; // if no slot, avoid drawing

        context.strokeStyle = this.getColor();
        context.clearRect(slot.left, slot.top, slot.width, slot.height);

        const obvValues = this.getResults() ? this.getResults() : this.calculate()!;

        // Determine the min and max OBV values in the visible range
        const minOBV = Math.min(...visibleDataPoints.map(d => obvValues[d.openTime] || 0));
        const maxOBV = Math.max(...visibleDataPoints.map(d => obvValues[d.openTime] || 0));

        context.save();
        context.beginPath();

        const obvRange = maxOBV - minOBV || 1; // prevent division by zero

        let isFirstPoint = true;

        for (let i = 0; i < visibleDataPoints.length; i++) {
            const openTime = visibleDataPoints[i].openTime;
            if (obvValues[openTime] !== undefined) {
                const xPos = transform.applyX(xScale(openTime) + 1 / 3);
                const normalizedOBV = (obvValues[openTime] - minOBV) / obvRange;
                const yPos = slot.top + height * (1 - normalizedOBV); // inverse to fit canvas

                if (isFirstPoint) {
                    context.moveTo(xPos, yPos);
                    isFirstPoint = false;
                } else {
                    context.lineTo(xPos, yPos);
                }
            }
        }

        context.stroke();
        context.restore();
    }

    public calculate(): { [key: number]: number } | null {
        this.result = null;
        const obvResults: { [key: number]: number } = {};

        if (this.data.length < 2) return null;

        let obv = 0;
        obvResults[this.data[0].openTime] = obv;

        for (let i = 1; i < this.data.length; i++) {
            const current = this.data[i];
            const previous = this.data[i - 1];

            if (current.close > previous.close) {
                obv += current.volume;
            } else if (current.close < previous.close) {
                obv -= current.volume;
            }

            obvResults[current.openTime] = obv;
        }

        this.result = obvResults;
        return obvResults;
    }
}