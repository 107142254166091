import React, {RefObject} from 'react';
import {
    Layout,
    KlineData,
    CandlestickChartProps,
    Execution,
    Signal,
    Trade
} from '../../shared/interfaces/interfaces.ts';
import * as d3 from 'd3';
import throttle from 'lodash/throttle';
import {IData} from "../../shared/models/data.model.ts";
import {
    calculatePixelWidthOfString,
    formatNumberToLocale,
    roundToTickSizeAsNumber
} from "../../shared/helper/conversions.ts";
import {Indicator, IndicatorStorage} from "../../shared/interfaces/indicator.interface.ts";
import LayoutCalculator from "./LayoutCalculator.ts";
import {isEmpty} from "underscore";
import Indicators from "../../shared/indicator";

export default class CandlestickChart  {

    private candlestickDataWindowed: KlineData[];
    //public canvasRef: React.RefObject<HTMLCanvasElement>;

    private throttle: number = 1000 / 60;
    public buySignals: Trade[];
    public sellSignals: Trade[];
    private props: CandlestickChartProps;

    private buyExecutions: Execution[] = [];
    private sellExecutions: Execution[] = [];
    private symbolMeta: IData;

    private scaleExtent: [number, number] = [1, 50];
    private visibleDataPointsLookUpTable: { [key: string]: number } = {};
    private visibleDataPoints: KlineData[] = [];
    private chartInterval = 3600000;

    public canvas: HTMLCanvasElement | null = null;

    private isLoading: boolean = false;
    private bandwidth: number = 1;
    private currentStart: number = 0;
    private currentEnd: number = 0;
    private minYPriceValue: number = 0;
    private maxYPriceValue: number = 0;
    private maxYImmutable: number = 0;
    private minYImmutable: number = 0;
    private startXPosition: number = 0;
    private lastDataPoint: Date = new Date();
    private firstDataPoint: Date = new Date();
    public selectedCandle: KlineData | undefined = undefined;
    public onScreenData: any = [];
    private showDebugInfo: boolean = false;
    private candlestickDataWindowSize: number = 0;
    private candlestickDataWindowSizeMultiplier: number = 1.5;

    private initialKScale: number = 5;
    private isDragging: boolean = false;
    private dragStart: { y: number } | null = null;
    private initialTransform: d3.ZoomTransform = d3.zoomIdentity;
    private currentKScale: number = 3;
    private currentXPosition: number = 0;
    private currentYPosition: number = 0;
    private zoom: d3.ZoomBehavior<HTMLCanvasElement, unknown> | undefined; // Optional machen

    private mouseCoordinates: { x: number, y: number } = {x: 0, y: 0};
    private yScale: d3.ScaleLinear<number, number> = d3.scaleLinear();
    private xScale: d3.ScaleTime<number, number> = d3.scaleTime();
    // private width: number = 0;
    // private height: number = 0;
    private margin: { top: number, right: number, bottom: number, left: number } = {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    };
    private downColor = 'rgb(96,96,96)';
    private upColor = 'rgb(24,128,24)';
    private downCandleInfoColor: string = 'rgb(154,93,93)';
    private upCandleInfoColor: string = '#446e53';
    private crossLineColor: string = '#808080';
    private tickLineColor: string = 'rgba(36,36,36)';
    private candleWidth: number = 1;
    private minBandWidth: number = 1;
    private renderedYPositions = new Set<number>();
    private renderedXPositions = new Set<Date>();
    private duration: number = 0;
    private maxWorkerInPool: number = navigator.hardwareConcurrency || 4;
    private layout: Layout;

    private throttledMouseMoveHandler: (event: MouseEvent) => void;
    protected candlestickData: KlineData[];
    protected context: CanvasRenderingContext2D | null = null;
    private longestPeriod = 0;
    protected indicators: Indicator[] = [];

    constructor(props: CandlestickChartProps & {
        buySignals: Trade[],
        sellSignals: Trade[],
        buyExecutions: Execution[],
        sellExecutions: Execution[],
        symbolMeta: IData,
        layout: LayoutCalculator,
        candlestickData: KlineData[] ,
        isLoading: boolean
    }) {


        this.props = props;
        this.candlestickData = props.candlestickData;
        //this.canvasRef = React.createRef<HTMLCanvasElement>();
        //this.canvas = this.canvasRef.current;

        this.layout = props.layout.getLayout();
        this.symbolMeta = props.symbolMeta;
        this.buySignals = props.buySignals;
        this.sellSignals = props.sellSignals;
        console.log('CandlestickChart: constructor',props.sellSignals.length, props.buySignals.length, props.buyExecutions.length, props.sellExecutions.length);
        this.buyExecutions = props.buyExecutions;
        this.sellExecutions = props.sellExecutions;

        this.candlestickDataWindowed = [];
        this.throttledMouseMoveHandler = throttle(this.handleMouseMove.bind(this), this.throttle);
    }

    updateCandlestickData(candlestickData: KlineData[]) {
        this.candlestickData = candlestickData;
    }

    updateSymbolMeta(symbolMeta: IData) {
        this.symbolMeta = symbolMeta;
    }

    setBuySignals(buySignals: Trade[]) {
        this.buySignals = buySignals;
    }

    setSellSignals(sellSignals: Trade[]) {
        this.sellSignals = sellSignals;
    }

    removeAllIndicator() {
        this.indicators = [];
    }

    instantiateIndicator(indicatorClassName: string, config: IndicatorStorage, id: string) {

        const IndicatorClass = (Indicators as any)[indicatorClassName];
        if (IndicatorClass) {
            return new IndicatorClass(config, id);
        }
    }

    getLongestPeriodOfIndicators() {
        if (this.longestPeriod) return this.longestPeriod;

        this.indicators.forEach(indicator => {
            if (indicator) {
                this.longestPeriod = this.longestPeriod <= indicator.getLongestPeriod()! ? indicator.getLongestPeriod()! : this.longestPeriod;
            } else {
                console.warn("Indicator is undefined.")
            }
        });
        return this.longestPeriod;
    }

    componentDidMount(canvasRef:RefObject<HTMLCanvasElement>) {
        console.log('componentDidMount: ');
        this.canvas = canvasRef.current;
        this.setupCanvasSize();
        this.init();
        this.setCandlestickDataWindowed(this.currentStart, this.currentEnd);
        this.updateAllIndicator();
        this.calculateAllIndicator();
        this.initializeCanvas();
        this.runDrawingTasks();
        this.init();
    }

    destroy() {
        this.candlestickData = [];

        if (this.canvas) {

            d3.select(this.canvas).on("dblclick.zoom", null);
            d3.select(this.canvas).on('zoom', null);

            this.canvas.removeEventListener('resize', () => {
                this.runDrawingTasks();
            });

            this.canvas.removeEventListener('mousedown', this.handleMouseDown);
            this.canvas.removeEventListener('mouseup', this.handleMouseUp);
            this.canvas.removeEventListener('mousemove', this.throttledMouseMoveHandler);
            this.canvas.removeEventListener('mouseleave', this.handleMouseLeave);
            this.canvas.removeEventListener('dblclick', this.handleMouseDoubleClick);
        }
        window.removeEventListener('contextmenu', this.handleContextMenu);
        window.removeEventListener('blur', this.handleWindowBlur);
    }

    componentDidUpdate(prevProps?: {
        layout: LayoutCalculator
    }) {

        if (prevProps && prevProps.layout) {
            this.layout = prevProps.layout.getLayout();
            console.log('componentDidUpdate: ', prevProps.layout.getLayout().symbolMenu.width);
        }
        this.setupCanvasSize();
        this.initializeCanvas();
        this.runDrawingTasks();
    }

    public setLayout(layout: Layout) {
        this.layout = layout;
    }

    public setIsResizing() {
        if (this.canvas) {
            this.isLoading = true;
            this.startXPosition = this.currentXPosition = d3.zoomTransform(this.canvas).x / d3.zoomTransform(this.canvas).k;
            this.currentYPosition = d3.zoomTransform(this.canvas).y / d3.zoomTransform(this.canvas).k;
            this.currentKScale = this.initialKScale = d3.zoomTransform(this.canvas).k;
            this.yScale.range(
                [this.layout.chartBox.bottom, this.layout.chartBox.top] // apply updated range
            );
        }
    }

    public setupCanvasSize() {

        if (!this.canvas) {
            console.error('Canvas not initialized.');
            return;
        }

        this.canvas.width = this.layout.canvas.width;
        this.canvas.height = this.layout.canvas.height;

        this.context?.clearRect(0, 0, this.layout.canvas.width, this.layout.canvas.height);

        const paddingTop = 0;

        this.margin = {top: paddingTop, right: this.layout.zoomYScaleBox.width, bottom: 0, left: 0};
        this.setCandleStickDataWindowSize();
    }

    public setCandleStickDataWindowSize() {

        this.longestPeriod = 0;
        this.candlestickDataWindowSize = this.layout.chartBox.width  * this.candlestickDataWindowSizeMultiplier + this.getLongestPeriodOfIndicators();
        this.currentStart = this.candlestickData.length - this.candlestickDataWindowSize;
        this.currentEnd = this.candlestickData.length;
    }

    public initStartXPosition() {
        if (this.canvas) {
            const startXPosition = (this.candlestickData.length < this.candlestickDataWindowed.length) ? 0 :
                -this.layout.chartBox.width +
                (this.candlestickDataWindowed.length * this.layout.chartBox.width / this.candlestickData.length);
            this.startXPosition = startXPosition;
        }
    }

    public initializeCanvas() {


        if (!this.canvas) {
            console.error('Canvas not initialized.');
            return;
        }

        this.context = this.canvas.getContext("2d");

        if (!this.context) {
            console.error('Context not initialized.');
            return;
        }


        this.chartInterval = this.symbolMeta.interval;

        if (!this.isLoading) {
            this.currentKScale = this.initialKScale;
            this.minYPriceValue = d3.min(this.candlestickData, d => d.low)!;
            this.maxYPriceValue = d3.max(this.candlestickData, d => d.high)!;

            if (this.candlestickDataWindowSize! > this.candlestickData.length) {
                this.currentXPosition = 0;
            } else {
                this.currentXPosition = -this.layout.chartBox.width - this.layout.chartBox.width / 8;
            }
        } else {

            this.currentXPosition = this.startXPosition;
        }

        this.maxYImmutable = this.maxYPriceValue;
        this.minYImmutable = this.minYPriceValue;

        this.initialTransform = d3.zoomIdentity
            .scale(this.currentKScale)
            .translate(this.currentXPosition, this.currentYPosition);

        if (this.zoom) {
            d3.select(this.canvas)
                .call(this.zoom).on("dblclick.zoom", null)
                .call(this.zoom.transform, this.initialTransform).on("dblclick.zoom", null);
        }


        if (isEmpty(this.candlestickDataWindowed)) return;

        const xScaleDomain = [
            new Date(
                this.candlestickDataWindowed[0].openTime
            ),
            new Date(
                this.candlestickDataWindowed[this.candlestickDataWindowed.length - 1].openTime
            )
        ];

        const xScaleRange = [
            this.layout.chartBox.left, this.candlestickDataWindowed.length
        ];

        this.xScale = d3.scaleTime()
            .domain(xScaleDomain)
            .range(xScaleRange);


        if (this.xScale.domain().length === 0) {
            console.error('The x scale domain is empty.');
            return;
        }

        this.lastDataPoint = this.xScale.domain()[1];
        this.firstDataPoint = this.xScale.domain()[0];

        this.generateLookUpTables();
        this.isLoading = false;
    }

    setCandlestickDataWindowed(start: number, end: number) {
        this.currentStart = start;
        this.currentEnd = end;

        if (this.candlestickData.length <= this.candlestickDataWindowSize!) {
            this.candlestickDataWindowed = this.candlestickData;
        } else {
            this.candlestickDataWindowed = this.candlestickData.slice(start, end);
        }

    }

    private initZoom() {

        const throttledHandleZoom = throttle((event: any) =>
            this.handleZoom(event), this.throttle);

        return d3.zoom<HTMLCanvasElement, unknown>()
            .scaleExtent(this.scaleExtent)
            .filter(() => !this.isDragging)
            .on("zoom", (event: any) => {
                throttledHandleZoom(event);
            })
            .on("end", () => {
                this.isDragging = false;
                this.runDrawingTasks();
            })
    }

    private handleZoom(event: any) {

        const transform = event.transform;

        if (this.context && !this.isDragging && transform && this.canvas) {
            this.currentKScale = transform.k;
            this.context.save();
            this.context.translate(transform.x, transform.y);
            this.context.scale(this.currentKScale, this.currentKScale);
            this.canvas.style.cursor = 'grabbing';
            this.context.restore();
            this.runDrawingTasks();
            if (event.sourceEvent) {
                const rect = this.canvas.getBoundingClientRect();
                this.drawCrosshair(event!.sourceEvent!.clientX! - rect.left, event!.sourceEvent!.clientY! - rect.top);
            }
        }

        const rightConditionValue = -this.candlestickDataWindowed.length + this.layout.chartBox.width / transform.k;
        const leftConditionForSmallSampleSizesAndScreens = (this.currentStart < 0 && this.candlestickDataWindowSize !== this.candlestickData.length);
        const leftConditionForBigSampleSizesAndScreens = (this.currentStart > 0 && this.candlestickDataWindowSize !== this.candlestickData.length);

        if (
            !this.isLoading && transform.x / transform.k > -this.getLongestPeriodOfIndicators() &&
            (
                leftConditionForBigSampleSizesAndScreens
                ||
                leftConditionForSmallSampleSizesAndScreens
            )
        ) {
            // console.warn('loadMoreData left', transform.x / transform.k, -this.getLongestPeriodOfIndicators());
            // console.log('conditions: ', leftConditionForSmallSampleSizesAndScreens,leftConditionForBigSampleSizesAndScreens)

            this.loadMoreData('left');
        } else if (
            (transform.x / transform.k) < rightConditionValue
            &&
            (this.currentEnd < this.candlestickData.length) &&
            !this.isLoading
        ) {
            // console.warn('loadMoreData right');
            this.loadMoreData('right');
        }
    }

    public loadMoreData(direction: 'left' | 'right') {
        if (this.isLoading) return;

        this.isLoading = true;
        //this.playBeep();
        if (direction === 'left' && this.canvas && this.candlestickDataWindowSize! < this.candlestickData.length) {

            const startIndex = Math.max(this.currentStart - this.candlestickDataWindowSize, 0);
            const endIndex = this.currentStart + this.candlestickDataWindowSize;

            this.startXPosition = (-this.candlestickDataWindowSize - this.getLongestPeriodOfIndicators())

            this.currentYPosition = d3.zoomTransform(this.canvas).y / d3.zoomTransform(this.canvas).k;
            this.setCandlestickDataWindowed(startIndex, endIndex);
            this.updateAllIndicator();
            //console.error('loadMoreData/left', startIndex, endIndex, this.candlestickDataWindowSize, this.candlestickData.length, this.startXPosition);
            this.initializeCanvas();
            //this.runDrawingTasks();
        } else if (direction === 'right' && this.canvas) {

            //  neuere Daten Laden, beim nach rechts scrollen
            const startIndex = this.currentStart + this.candlestickDataWindowSize;
            const endIndex = Math.min(this.currentEnd + this.candlestickDataWindowSize, this.candlestickData.length);

            if (startIndex < endIndex) { // not end of array
                this.setCandlestickDataWindowed(startIndex, endIndex);

                this.startXPosition = 0;
                this.startXPosition = (-this.candlestickDataWindowSize) + this.layout.chartBox.width / d3.zoomTransform(this.canvas).k;

                this.currentYPosition = d3.zoomTransform(this.canvas).y / d3.zoomTransform(this.canvas).k;
                // console.warn('loadMoreData/right',
                //     [this.currentStart, this.currentEnd],
                //     [startIndex, endIndex],
                //     [this.candlestickDataWindowSize, this.candlestickDataWindowed.length],
                //     [this.startXPosition, this.currentXPosition],
                //     [d3.zoomTransform(this.canvas).k, d3.zoomTransform(this.canvas).x]
                // );
                this.updateAllIndicator();
                this.initializeCanvas();

            } else {
                this.isLoading = false;
            }
        }
    }

    drawAllOverlayIndicator() {
        if (this.context && this.canvas) {
            const transform = d3.zoomTransform(this.canvas);
            this.indicators.forEach((indicator) => {
                if (!indicator.isBoxed()) {
                    indicator.draw(this.visibleDataPoints, this.context!, this.xScale, this.yScale, transform, undefined)
                }
            });
        }
    }

    drawAllBoxedIndicator() {
        if (this.context && this.canvas) {
            const transform = d3.zoomTransform(this.canvas);
            this.indicators.forEach((indicator) => {
                if (indicator.isBoxed()) {
                    indicator.draw(this.visibleDataPoints, this.context!, this.xScale, this.yScale, transform, this.layout)
                }
            });
        }
    }

    updateAllIndicator() {
        this.indicators.forEach(indicator => {
            indicator.clearCaches();
            indicator.setInputData(this.candlestickDataWindowed);
        });
    }

    addIndicator(indicator: Indicator) {

        if (indicator) {
            indicator.setInputData(this.candlestickDataWindowed);
            this.indicators.push(indicator);
        }
    }

    calculateAllIndicator() {
        this.indicators.forEach((indicator) => {
            indicator.calculate();
        })
    }

    getCandlestickData(): KlineData[] {
        return this.candlestickDataWindowed;
    }

    setSelectedCandle(candle: KlineData | undefined) {
        this.selectedCandle = candle;
        if (candle) {
            this.onScreenData = [];
            this.addToOnScreenData(
                {
                    type: 'CurrentCandle',
                    candle: candle
                }
            );

            this.indicators.forEach((indicator: Indicator) => {
                if (indicator) {
                    this.addToOnScreenData(
                        {
                            type: 'SimpleOverlay',
                            name: indicator.getConfig().name,
                            period: indicator.getPeriod(),
                            value: indicator.getResult(candle.openTime),
                            color: indicator.getColor(),
                        }
                    )
                }
            })
            this.setOnScreenData(this.onScreenData);
        }
    }

    addToOnScreenData(data: any | null) {
        this.onScreenData.push(data);
    }

    setOnScreenData(onScreenData: any[] | undefined) {
        this.onScreenData = onScreenData;
    }

    getSelectedCandle(): KlineData | undefined {
        return this.selectedCandle;
    }

    roundToNearest(value: number, factor: number): number {
        return Math.round(value / factor) * factor;
    }

    // Hilfsfunktion zur Auswahl des besten Rundungsfaktors
    getBestRoundingFactor(range: number, roundingFactors: number[]): number {
        for (let factor of roundingFactors) {
            if (range <= factor) {
                return factor;
            }
        }
        return roundingFactors[roundingFactors.length - 1];
    }

    // Funktion zur Erstellung der Rundungsfaktorenliste
    generateRoundingFactors(): number[] {
        const factors = [];
        const baseFactors = [1, 2, 5, 10];  // Basisfaktoren für Rundung

        // Hinzufügen von kleineren Faktoren für Dezimalwerte
        for (let exponent = -18; exponent <= 6; exponent++) {  // Bereich der Exponenten
            for (let base of baseFactors) {
                factors.push(base * Math.pow(10, exponent));
            }
        }
        return factors.sort((a, b) => a - b);  // Sortieren der Faktoren
    }

    drawAll() {
        if (!this.context || !this.canvas) return;

        this.context.clearRect(0, 0, this.layout.canvas.width, this.layout.canvas.height);

        const transform = d3.zoomTransform(this.canvas)
        this.generateLookUpTables();
        if (transform) {
            this.context.clearRect(0, 0, this.layout.canvas.width, this.layout.canvas.height);
            this.context.save();
            this.context.translate(transform.x, transform.y);
            this.context.scale(transform.k, transform.k);
            this.drawChartElements();
            this.drawSignals();
            this.context.restore();
            this.drawAllBoxedIndicator();
            this.drawDateLabels();
            this.drawZoomYScaleBox()
            this.drawConfigBox();
            this.drawAbsoluteText();
        }
    };

    drawConfigBox() {
        if (!this.context || !this.canvas) return;
        this.context.clearRect(this.layout.chartBox.width, this.layout.zoomYScaleBox.bottom, this.layout.zoomYScaleBox.width, this.layout.dateBarAreaBox.height);
    }

    handleMouseUp = (event: MouseEvent) => {
        this.canvas!.style.cursor = 'initial';
        if (this.isDragging) {
            this.isDragging = false;
            this.dragStart = null;
        }

        if (event.button === 2) {
            //    d3.select(canvas).on('zoom', null);
        }
        this.runDrawingTasks();
    };

    handleContextMenu = (event: MouseEvent) => {
        this.showDebugInfo = event.altKey && !this.showDebugInfo;
        event.preventDefault();
    }

    handleMouseLeave = (event: MouseEvent) => {
        if (this.isDragging) {
            this.isDragging = false;
            this.dragStart = null;
        }
        //this.drawAll();
        this.runDrawingTasks();
    };

    handleWindowBlur = () => {
        if (this.isDragging) {
            this.isDragging = false;
            this.dragStart = null;

            console.log('mm/blur');
        }
        this.runDrawingTasks();
        // this.drawAll();
    };

    isInYScaleBox() {
        if (
            this.mouseCoordinates.x >= this.layout.zoomYScaleBox.left &&
            this.mouseCoordinates.x <= this.layout.zoomYScaleBox.right &&
            this.mouseCoordinates.y >= this.layout.zoomYScaleBox.top &&
            this.mouseCoordinates.y <= this.layout.zoomYScaleBox.bottom
        ) return true;
        return false;
    }

    handleMouseDown = (event: MouseEvent) => {

        if (
            this.layout.zoomYScaleBox &&
            event.button === 0 && this.isInYScaleBox()
        ) {

            this.isDragging = true;
            this.dragStart = {y: event.clientY};
        }
        this.runDrawingTasks();
    };

    getHighestHighAndLowestLow() {

        let highestHigh = -Infinity;
        let lowestLow = Infinity;
        let count = 0
        this.visibleDataPoints.forEach((dataPoint) => {
            // Transformiere die y-Werte ohne die logische Reihenfolge zu ändern
            const transformedHigh = dataPoint.high;
            const transformedLow = dataPoint.low;

            // Aktualisiere die höchsten und niedrigsten Werte basierend auf den transformierten Koordinaten
            if (transformedHigh > highestHigh) {
                highestHigh = transformedHigh;
            }
            if (transformedLow < lowestLow) {
                lowestLow = transformedLow;
            }
            count++;
        });


        // Sichere die logische Reihenfolge von highestHigh und lowestLow
        return {
            highestHigh: Math.max(lowestLow, highestHigh),
            lowestLow: Math.min(highestHigh, lowestLow),
            count: count
        };
    }

    rescaleYScale() {

        if (!this.canvas) return;
        // Ermittle höchste und niedrigste Werte
        let {highestHigh, lowestLow, count} = this.getHighestHighAndLowestLow();
        if (count <= 0) {
            console.warn('rescaleYScale/no data');
            return;
        }

        const transform = d3.zoomTransform(this.canvas);
        const zoomFactor = transform.k;
        const visibleRange = Math.abs(lowestLow - highestHigh);

        // 10% Puffer
        const buffer = visibleRange * 0.10;

        lowestLow = lowestLow - visibleRange * (zoomFactor - 1);

        this.yScale
            .domain([
                Math.min(highestHigh, lowestLow) - buffer * zoomFactor,
                Math.max(highestHigh, lowestLow) + buffer
            ]).range(
            [this.layout.chartBox.bottom, this.layout.chartBox.top] // apply updated range
        );

        const yTranslate = -this.yScale(highestHigh + buffer / zoomFactor);
        // Der X-Offset bleibt unverändert
        const newTransform = d3.zoomIdentity
            .translate(transform.x, yTranslate)
            .scale(zoomFactor);

        // Wenden Sie den neuen Transform an
        if (this.zoom) {
            d3.select(this.canvas).call(this.zoom.transform, newTransform);
        }

        // Zeichne aktualisierte Diagramme
        this.runDrawingTasks();
    }

    handleMouseDoubleClick = () => {
        if (this.layout.zoomYScaleBox &&
            this.isInYScaleBox() ||
            this.isDragging) {
            this.rescaleYScale();
        }
    }

    isInChartbox(clientX: number, clientY: number) {
        return clientY > this.layout.chartBox.top &&
            clientY < this.layout.chartBox.bottom &&
            clientX > this.layout.chartBox.left &&
            clientX < this.layout.chartBox.right
    }

    drawCrosshair(clientX: number, clientY: number, color: string = this.crossLineColor) {
        if (!this.canvas || !this.context) return;
        const transform = d3.zoomTransform(this.canvas);
        this.context.save()
        const cursorLineWidth = 1;

        if (this.isInChartbox(clientX, clientY) && this.canvas) {
            this.canvas.style.cursor = 'crosshair';

            const xCursorPosition = clientX + this.layout.chartBox.left;
            const adjustedXCursorPosition = (xCursorPosition - transform.x) / this.currentKScale;

            const candleOpenTime = Math.floor(this.xScale.invert(adjustedXCursorPosition).getTime() / this.chartInterval) * this.chartInterval;
            const candleIndex = this.visibleDataPointsLookUpTable[candleOpenTime];

            let xPos = (clientX);

            if (candleIndex >= 0 && candleIndex <= this.candlestickDataWindowed.length) {
                this.setSelectedCandle(this.candlestickDataWindowed[candleIndex]);
                xPos = (transform.applyX(this.xScale(candleOpenTime)) + (this.candleWidth / 2) * transform.k);
            } else {
                this.setSelectedCandle(undefined);
            }

            const yPos = clientY;
            const yPosTransformed = (yPos - transform.y) / transform.k

            // vertical crossline
            this.context.strokeStyle = color || this.crossLineColor;
            this.context.lineWidth = cursorLineWidth;

            this.context.beginPath();
            this.context.moveTo(xPos, this.layout.chartBox.top);
            this.context.lineTo(xPos, this.layout.chartBox.bottom);

            this.context.moveTo(xPos, this.layout.chartBox.bottom +
                this.layout.dateLabelHeight * 2 +
                this.layout.inlinePadding);
            this.context.lineTo(xPos, this.canvas.height - this.layout.canvas.margin.bottom);
            this.context.stroke();
            this.context.closePath();

            const roundedPriveValue = roundToTickSizeAsNumber(
                this.yScale.invert(yPosTransformed),
                this.symbolMeta.priceFilter.tickSize,
                this.symbolMeta.priceFilter.decimals
            );

            const priceAtYPos = formatNumberToLocale(roundedPriveValue,
                this.layout.browserLanguage, undefined,
                this.symbolMeta.priceFilter.tickSize
            );

            const priceLabelWidth = calculatePixelWidthOfString(priceAtYPos.toString()) + this.layout.inlinePadding;

            const scaledFontSize = 14;

            // dateTime
            if (typeof this.getSelectedCandle() !== 'undefined') { // if on candle

                // DateTime in der fixedBox
                this.context.fillStyle = "gray";

                this.context.font = "bold " + scaledFontSize + "px Ubuntu, Verdana, 'sans serif'";
                this.context.textAlign = "center";
                this.context.textBaseline = "top";
                this.context.fillText(
                    new Date(this.getSelectedCandle()!.openTime!).toLocaleTimeString(),
                    xPos,
                    (
                        this.layout.boxedIndicatorArea.height < this.layout.dateBarAreaBox.height ?
                            this.layout.chartBox.bottom - this.layout.dateBarAreaBox.height - 5 :
                            this.layout.chartBox.bottom + this.layout.dateBarAreaBox.height + 5

                    )
                );

                this.context.fillText(
                    new Date(this.getSelectedCandle()!.openTime!).toLocaleDateString(),
                    xPos,
                    this.layout.boxedIndicatorArea.height < this.layout.dateBarAreaBox.height ?
                        this.layout.chartBox.bottom - this.layout.dateBarAreaBox.height + 15 :
                        this.layout.chartBox.bottom + this.layout.dateBarAreaBox.height + 20)

            }

            this.context.strokeStyle = "#A0A0A0";
            this.context.lineWidth = 20;

            // price label box at horizontale line
            this.context.beginPath();
            this.context.moveTo(this.layout.chartBox.right - priceLabelWidth, yPos);
            this.context.lineTo(this.layout.chartBox.right, yPos);
            this.context.stroke();
            this.context.closePath();
            const horizotalLineTo = this.layout.chartBox.right - priceLabelWidth;
            // horizontal cross line
            this.context.lineWidth = cursorLineWidth;
            this.context.strokeStyle = color || this.crossLineColor;
            this.context.beginPath();
            this.context.moveTo(this.layout.chartBox.left, yPos);
            this.context.lineTo(horizotalLineTo, yPos);
            this.context.stroke();
            this.context.closePath();

            // price (right hand)
            this.context.fillStyle = "#000000";
            this.context.textAlign = "right";
            this.context.textBaseline = "middle";
            this.context.font = scaledFontSize + "px Ubuntu, Verdana, 'sans serif'";
            this.context.fillText(
                priceAtYPos.toString(),
                this.layout.chartBox.right - this.layout.inlinePadding, yPos);
            this.context.restore();
        }
    }

    runDrawingTasks() {
        this.drawAll();
    }

    setMouseCoordinates(event: MouseEvent) {
        if (!this.canvas) return;
        const rect = this.canvas.getBoundingClientRect();
        this.mouseCoordinates.x = event.clientX - rect.left;
        this.mouseCoordinates.y = event.clientY - rect.top;
    }

    handleMouseMove = (event: MouseEvent) => {
        if (!this.canvas) return;
        this.setMouseCoordinates(event);

        const transform = d3.zoomTransform(this.canvas);

        if (this.isInChartbox(this.mouseCoordinates.x, this.mouseCoordinates.y) &&
            !this.isDragging && this.context) {
            this.runDrawingTasks();
            this.drawCrosshair(this.mouseCoordinates.x, this.mouseCoordinates.y);
        } else if (
            this.layout.zoomYScaleBox && this.isInYScaleBox() ||
            this.isDragging
        ) {
            this.canvas.style.cursor = 'ns-resize';
            this.runDrawingTasks();
        } else {
            this.canvas.style.cursor = 'initial';
            this.runDrawingTasks();
        }

        //  yScale Zoom
        if (this.isDragging && this.dragStart) {
            // console.log('mm/yScale-scale', transform, this.dragStart, event.clientY, this.dragStart.y);
            const dy = this.mouseCoordinates.y - this.dragStart.y;

            // Höhe des Canvas
            const canvasHeight = Math.abs(this.layout.chartBox.top - this.layout.chartBox.bottom);

            // Finden des Preiswerts beim vertikalen Mittelpunkt des sichtbaren Bereichs
            const midCanvasY = canvasHeight / 2;
            const priceAtMidPoint = this.yScale.invert((midCanvasY - transform.y) / transform.k);

            const zoomFactor = 1.075; // Zoom-Faktor
            let newMinPrice, newMaxPrice;

            if (dy < 0) { // Zoom out
                newMinPrice = priceAtMidPoint - (priceAtMidPoint - this.yScale.domain()[0]) / zoomFactor;
                newMaxPrice = priceAtMidPoint + (this.yScale.domain()[1] - priceAtMidPoint) / zoomFactor;
            } else if (dy > 0) { // Zoom in
                newMinPrice = priceAtMidPoint - (priceAtMidPoint - this.yScale.domain()[0]) * zoomFactor;
                newMaxPrice = priceAtMidPoint + (this.yScale.domain()[1] - priceAtMidPoint) * zoomFactor;
            } else {
                return; // Keine Änderungen
            }

            // Set the new domain
            this.minYPriceValue = newMinPrice;
            this.maxYPriceValue = newMaxPrice;
            this.yScale.domain([newMinPrice, newMaxPrice]);
            this.runDrawingTasks();
            this.dragStart = {y: this.mouseCoordinates.y};
        }
    };

    drawDebugInfo() {

        // console.log(this.xScale.domain());


        if (this.context && this.canvas && this.showDebugInfo) {
            this.context.save();
            const transform = d3.zoomTransform(this.canvas);
            let lastDataPointDate = '';

            if (this.lastDataPoint && this.firstDataPoint) {
                lastDataPointDate = new Date(Number(this.lastDataPoint)).toISOString();
            }

            this.context.fillStyle = "lightgray";
            this.context.font = "18px Ubuntu, Verdana, 'sans serif'";
            this.context.textAlign = "left";
            this.context.textBaseline = "bottom";
            this.context.fillText(
                'xScaleRange: ' + this.xScale.range() +
                ' xScaleDomain: ' + this.xScale.domain() +
                ' length: ' + this.xScale.domain().length
                , 10, this.layout.canvas.height - 144);

            this.context.fillText(
                'yScaleRange: ' + this.yScale.range()[0].toFixed(2) + ' : ' + this.yScale.range()[1].toFixed(2) +
                ' yScaleDomain: ' + this.yScale.domain(), 10, this.layout.canvas.height - 120);

            this.context.fillText(
                'transform: ' + transform.toString(), 10, this.layout.canvas.height - 96);

            this.context.fillText(
                'totalCandles: ' + this.candlestickData.length +
                ' windowed.length:windowSize: ' + this.candlestickDataWindowed.length + ':' + this.candlestickDataWindowSize
                , 10, this.layout.canvas.height - 72);

            this.context.fillText(
                'MousePointer: ' + this.mouseCoordinates.x + ':' + this.mouseCoordinates.y + ' MaxPeriod: ' + this.getLongestPeriodOfIndicators(), 10, this.layout.canvas.height - 48);

            this.context.fillText(
                'currentCandleOpenTime: ' + this.getSelectedCandle()?.openTime, 10, this.layout.canvas.height - 24);

            this.context.fillText(
                'currentScaleFactor(k): ' +
                this.currentKScale.toFixed(4) + ' FirstDataPoint: ' +
                new Date(Number(this.firstDataPoint)).toISOString() + ' LastDataPoint: ' + lastDataPointDate, 10, this.layout.canvas.height - 0);
            this.context.restore();
        }
    }

    drawHorizontalPriceLines() {
        if (!this.canvas || !this.context) return;
        this.renderedYPositions.clear();
        const minimumRowLineSpacing = 15;
        const transform = d3.zoomTransform(this.canvas);
        const differenceOfYScaleDomain = Math.abs(this.yScale.domain()[0] - this.yScale.domain()[1]);
        let numLines = 15 * this.currentKScale;

        const difference = Math.abs(this.layout.chartBox.bottom - this.layout.chartBox.top);
        const optimalLineSpacing = Math.round(difference / numLines) / this.currentKScale > minimumRowLineSpacing ?
            Math.round(difference / numLines) / this.currentKScale : minimumRowLineSpacing;
        const adjustedMaxYRange = this.layout.chartBox.top - transform.y / this.currentKScale;
        const adjustedMinYRange = this.layout.chartBox.bottom - transform.y / this.currentKScale;

        // Generierung der Rundungsfaktorenliste
        const roundingFactors = this.generateRoundingFactors();

        // Flexiblen Rundungsfaktor berechnen, der auch Nachkommastellen berücksichtigt
        let bestRoundingFactor = this.getBestRoundingFactor(differenceOfYScaleDomain / numLines, roundingFactors);

        for (let yPos = adjustedMaxYRange; yPos <= adjustedMinYRange; yPos += optimalLineSpacing) {
            this.context.save();
            let yPriceValue = this.yScale.invert(yPos);
            yPriceValue = this.roundToNearest(yPriceValue, bestRoundingFactor);
            if (!this.renderedYPositions.has(yPriceValue) && this.context) {
                this.renderedYPositions.add(yPriceValue);
                this.context.beginPath();
                this.context.strokeStyle = this.tickLineColor;
                this.context.lineWidth = 1.5 / this.currentKScale;
                this.context.moveTo(Number(this.lastDataPoint), this.yScale(yPriceValue));
                this.context.lineTo(0, this.yScale(yPriceValue));
                this.context.stroke();
            }
            this.context.restore();
        }

    };

    drawSignals() {
        // console.warn( this.props);
        if (!this.context || !this.canvas) return;
        const triangleHeight = 18; // Höhe des Dreiecks
        const triangleWidth = (triangleHeight * Math.sqrt(3)) / 2; // Breite des gleichseitigen Dreiecks
        const fontSize = 12;
        const transform = d3.zoomTransform(this.canvas);

        this.buySignals.forEach(signal => {
            if (
                this.candlestickDataWindowed[0].openTime <= signal.timestamp &&
                this.candlestickDataWindowed[this.candlestickDataWindowed.length - 1].openTime >= signal.timestamp &&
                this.context
            ) {

                // const xPos = (transform.x + this.xScale(signal.timestamp)! + this.candleWidth / 2 );
                const xPos = (transform.applyX(this.xScale(signal.timestamp) + this.candleWidth / 2));
                const yPos = (transform.applyY(this.yScale(signal.price)));
                this.context.save();

                this.context.beginPath();
                this.context.moveTo(xPos, yPos); // Mittelpunkt der Basislinie
                // Berechne die Koordinaten der Ecken des Dreiecks
                this.context.lineTo(xPos - triangleWidth / 2, yPos + triangleHeight); // Linker Eckpunkt
                this.context.lineTo(xPos + triangleWidth / 2, yPos + triangleHeight); // Rechter Eckpunkt
                this.context.closePath();

                this.context.fillStyle = 'lightgreen'; // Farbe des Dreiecks
                this.context.fill();
                this.context.textAlign = 'center';
                this.context.font = fontSize + 'px Ubuntu, Verdana, \'sans serif\'';

                this.context.fillText(
                    signal.capital!.toFixed(4),
                    xPos,
                    yPos + triangleHeight + this.layout.inlinePadding + 15
                );
                this.context.restore();
            }
        });

        // Zeichne Verkauf-Signale (Dreiecke nach unten)
        this.sellSignals.forEach(signal => {
            if (
                this.candlestickDataWindowed[0].openTime <= signal.timestamp &&
                this.candlestickDataWindowed[this.candlestickDataWindowed.length - 1].openTime >= signal.timestamp &&
                this.context
            ) {
                const xPos = (transform.applyX(this.xScale(signal.timestamp)! + this.candleWidth / 2));
                const yPos = (transform.applyY(this.yScale(signal.price!)));
                this.context.save();
                this.context.beginPath();
                this.context.moveTo(xPos, yPos); // Spitze des Dreiecks
                this.context.lineTo(xPos - triangleWidth / 2, yPos - triangleHeight); // Linker Eckpunkt
                this.context.lineTo(xPos + triangleWidth / 2, yPos - triangleHeight); // Rechter Eckpunkt
                this.context.closePath();

                this.context.fillStyle = 'red'; // Farbe des Dreiecks für Verkauf-Signale
                this.context.fill();

                this.context.textAlign = 'center';
                this.context.font = fontSize + 'px Ubuntu, Verdana, \'sans serif\'';

                this.context.fillText(
                    signal.capital!.toFixed(4),
                    xPos,
                    yPos - triangleHeight - this.layout.inlinePadding - 5
                );
                this.context.restore();
            }
        });
    }

    drawSymbolWaterMark() {

        if (this.context && this.canvas) {
            const fontsize = (this.layout.chartBox.width / 10) / d3.zoomTransform(this.canvas).k;
            const xPos = (this.layout.chartBox.width / 2 - d3.zoomTransform(this.canvas).x) / d3.zoomTransform(this.canvas).k;
            const yPos = (this.layout.chartBox.height / 2 - d3.zoomTransform(this.canvas).y) / d3.zoomTransform(this.canvas).k;
            this.context.save();
            this.context.fillStyle = this.layout.chartBox.watermarkColor;
            this.context.font = "900 " + fontsize + "px Ubuntu";
            this.context.textAlign = "center";
            this.context.textBaseline = "bottom";
            this.context.fillText(this.symbolMeta.symbol, xPos, yPos);
            this.context.textBaseline = "top";
            this.context.font = "700 " + fontsize * 0.46 + "px Ubuntu";
            this.context.fillText('ChartRider Platform', xPos, yPos);
            this.context.restore();
        }
    }

    drawAbsoluteText() {
        if (this.context) {
            this.drawDebugInfo();
        }
    };

    drawDateLabels() {

        if (!this.context || !this.canvas) return;
        this.context.save();
        const transform = d3.zoomTransform(this.canvas);

        this.context.beginPath();
        this.context.fillStyle = this.layout.dateBarAreaBox.color;
        this.context.fillRect(
            this.layout.dateBarAreaBox.left,
            this.layout.dateBarAreaBox.top,
            this.layout.dateBarAreaBox.width,
            this.layout.dateBarAreaBox.height
        );

        this.context.closePath();
        this.renderedXPositions.forEach((xTimeValue) => {
            if (!this.context) return;

            if (xTimeValue) {
                this.context.fillStyle = "gray";
                this.context.font = `12px Ubuntu`;
                this.context.textAlign = "center";
                this.context.textBaseline = "bottom";

                this.context.fillText(
                    xTimeValue.toLocaleDateString('de'),
                    transform.applyX(this.xScale(xTimeValue)) + transform.k * this.candleWidth / 2,
                    this.layout.dateBarAreaBox.top + 15
                );

                this.context.fillText(
                    xTimeValue.toLocaleTimeString('de'),
                    transform.applyX(this.xScale(xTimeValue)) + transform.k * this.candleWidth / 2,
                    this.layout.dateBarAreaBox.top + 30
                );

            }

        });

        this.context.restore();
    };

    drawZoomYScaleBox() {
        if (!this.context || !this.canvas || !this.layout.zoomYScaleBox) return;
        this.context.save();
        const transform = d3.zoomTransform(this.canvas);
        this.context.beginPath();
        this.context.fillStyle = this.layout.zoomYScaleBox.color;
        this.context.fillRect(
            this.layout.zoomYScaleBox.left,
            this.layout.zoomYScaleBox.top,
            this.layout.zoomYScaleBox.width,
            this.layout.zoomYScaleBox.height
        );

        this.renderedYPositions.forEach((price) => {
            if (!this.context || !this.layout.zoomYScaleBox) return;
            const yPos = this.yScale(price) * this.currentKScale + transform.y;
            if (yPos <= this.layout.zoomYScaleBox.height && yPos >= this.layout.zoomYScaleBox.top) {
                this.context.fillStyle = "gray";
                this.context.font = "12px Ubuntu";
                this.context.textAlign = "left";
                this.context.textBaseline = "middle";
                this.context.fillText(
                    formatNumberToLocale(price,
                        this.layout.browserLanguage,
                        undefined,
                        this.symbolMeta.priceFilter.tickSize).toString(),
                    this.layout.zoomYScaleBox.left + this.layout.inlinePadding,
                    yPos);
            }
        })
        this.context.restore();
    }
    ;


    drawChartElements() {
        if (!this.context || !this.canvas) return;  // Sicherstellen, dass der Kontext nicht null ist

        this.renderedXPositions.clear();
        this.drawHorizontalPriceLines();
        this.drawVerticalTimeLines();

        this.drawSymbolWaterMark();
        this.visibleDataPoints.forEach((dataPoint) => {
            if (this.context) {
                const xPos = this.xScale(new Date(dataPoint.openTime))!;
                this.drawCandle(xPos, dataPoint);
            }
        });
        this.drawAllOverlayIndicator();
    }

    generateLookUpTables() {
        if (this.candlestickDataWindowed.length > 0 && this.canvas) {
            const transform = d3.zoomTransform(this.canvas);
            let visibleIndex = 0; // Hält die Position für die nächste direkte Zuweisung

            this.candlestickDataWindowed.forEach((dataPoint, index) => {
                const xPos = this.xScale(new Date(dataPoint.openTime))!;
                const transformedXPos = xPos * transform.k + transform.x;

                // Prüfen Sie, ob der Datenpunkt sichtbar ist
                if (transformedXPos + 2 * transform.k >= this.layout.chartBox.left &&
                    transformedXPos < this.layout.chartBox.right + this.layout.zoomYScaleBox.width + transform.k
                ) {
                    this.visibleDataPoints[visibleIndex++] = dataPoint;  // Direkte Zuweisung mit Index
                    const timeKey = dataPoint.openTime.toString();
                    this.visibleDataPointsLookUpTable[timeKey] = index;
                }
            });

            // Optional: Trimmen auf die tatsächliche Länge
            this.visibleDataPoints.length = visibleIndex;
        }
    }

    drawVerticalTimeLines() {
        if (!this.canvas || !this.context || !this.visibleDataPoints[0]) return;
        this.renderedXPositions.clear();
        const transform = d3.zoomTransform(this.canvas);
        const minimumColLineSpacing = 60;
        // Dynamische Berechnung der Anzahl der Linien basierend auf dem Zoomfaktor
        let baseLines = 10; // Baseline Anzahl von Linien
        let numLines = Math.floor(baseLines * Math.round(this.currentKScale)); // Erhöhen Sie die Anzahl der Linien basierend auf dem Zoomfaktor

        const difference = Math.abs(this.layout.chartBox.right - this.layout.chartBox.left);
        const optimalLineSpacing = Math.round(difference / numLines) > minimumColLineSpacing ?
            Math.round(difference / numLines) : minimumColLineSpacing;

        const adjustedMinXRange = this.layout.chartBox.left;
        const adjustedMaxXRange = this.layout.chartBox.right + this.candlestickDataWindowed.length!;

        let count = 0;

        for (let xPos = adjustedMinXRange; xPos <= adjustedMaxXRange; xPos += optimalLineSpacing) {
            this.context.save();

            let xTimeValue = this.xScale.invert(xPos);
            xTimeValue = this.roundToNearestTime(xTimeValue);

            if (this.visibleDataPoints[0]!.openTime <= xTimeValue.getTime()
                && this.visibleDataPoints[this.visibleDataPoints.length - 1]!.openTime >= xTimeValue.getTime()
                && !this.renderedXPositions.has(xTimeValue) && this.context
            ) {
                // console.log('mm/drawVerticalTimeLines: xPos: ' + xPos + ' xTimeValue: ' + xTimeValue.toISOString());
                this.renderedXPositions.add(xTimeValue);

                this.context.beginPath();
                this.context.strokeStyle = this.tickLineColor;
                this.context.lineWidth = 1.5 / this.currentKScale;
                this.context.moveTo(this.xScale(xTimeValue) + this.candleWidth / 2, (this.margin.top - transform.y) / transform.k);
                this.context.lineTo(this.xScale(xTimeValue) + this.candleWidth / 2, (this.layout.chartBox.bottom - transform.y) / transform.k);
                this.context.stroke();
                this.context.closePath();
                count++;
            }
            this.context.restore();
        }
    }
    ;

    roundToNearestTime(time: Date): Date {
        const coeff = 60 * 60 * 1000; // Runden auf nächste Stunde (Mikroenheiten)
        return new Date(Math.round(time.getTime() / coeff) * coeff);
    }

    drawCandle(xPos: number, dataPoint: KlineData) {

        const minCandleWidth = this.minBandWidth; // Mindestbreite der Candlesticks in Pixel
        const candleMarginX = 3 - 1 / this.currentKScale;
        this.candleWidth = Math.min(this.bandwidth, minCandleWidth) - 1 / candleMarginX;

        const wickSize = 1 / this.currentKScale;
        const bodySize = (this.candleWidth) / this.currentKScale;
        if (this.context) {

            const openY = this.yScale(dataPoint.open);
            const highY = this.yScale(dataPoint.high);
            const lowY = this.yScale(dataPoint.low);
            const closeY = this.yScale(dataPoint.close);

            this.context.strokeStyle = dataPoint.close > dataPoint.open ? this.upColor : this.downColor;
            this.context.fillStyle = dataPoint.close > dataPoint.open ? this.upColor : this.downColor;
            this.context.lineWidth = wickSize;

            // High-Low Linie - wicks
            this.context.beginPath();
            this.context.moveTo(xPos + this.candleWidth / 2, highY);
            this.context.lineTo(xPos + this.candleWidth / 2, lowY);
            this.context.stroke();
            this.context.closePath();

            // Candlestick-Körper - bodies
            this.context.lineWidth = bodySize;
            this.context.fillRect(
                xPos,
                Math.min(openY, closeY),
                this.candleWidth,
                Math.abs(closeY - openY)
            );

            this.context.strokeRect(
                xPos,
                Math.min(openY, closeY),
                this.candleWidth,
                Math.abs(closeY - openY)
            );
        }
    }

    init(): (() => void) | undefined {

        if (!this.canvas) {
            console.error('Canvas not initialized.');
            return;
        }




        if (!this.isLoading) {

            this.yScale = d3.scaleLinear()
                .domain([this.minYImmutable, this.maxYImmutable])
                .range([this.layout.chartBox.bottom, this.layout.chartBox.top]);

            this.canvas.addEventListener('dblclick', this.handleMouseDoubleClick);
            this.canvas.addEventListener('mousemove', this.throttledMouseMoveHandler);
            this.canvas.addEventListener('mouseup', this.handleMouseUp);
            this.canvas.addEventListener('mousedown', this.handleMouseDown);

            this.zoom = this.initZoom();

            if (this.zoom) { // Optional chaining, um sicherzugehen, dass this.zoom nicht undefined ist
                const selection = d3.select(this.canvas);
                selection.call(this.zoom).on("dblclick.zoom", null);
                selection.call(this.zoom.transform, this.initialTransform);
            } else {
                console.error('Zoom not initialized.');
            }

            this.canvas.addEventListener('mouseleave', this.handleMouseLeave);
            window.addEventListener('contextmenu', this.handleContextMenu);
            window.addEventListener('blur', this.handleWindowBlur);
        }

        return () => {
            console.warn(
                'mm/cleanup');

            if (this.canvas) {

                d3.select(this.canvas).on('zoom', null);
                d3.select(this.canvas).on("dblclick.zoom", null);

                this.canvas.removeEventListener('resize', () => {
                    this.runDrawingTasks();
                });

                this.canvas.removeEventListener('mousedown', this.handleMouseDown);
                this.canvas.removeEventListener('mouseup', this.handleMouseUp);
                this.canvas.removeEventListener('mousemove', this.throttledMouseMoveHandler);
                this.canvas.removeEventListener('mouseleave', this.handleMouseLeave);
                this.canvas.removeEventListener('dblclick', this.handleMouseDoubleClick);
            }
            window.removeEventListener('contextmenu', this.handleContextMenu);
            window.removeEventListener('blur', this.handleWindowBlur);
        };
    }

    playBeep() {
        // Erstelle einen neuen AudioContext
        const audioCtx = new (window.AudioContext || window.AudioContext)();

        // Erstelle einen Oszillator und stelle ihn ein
        const oscillator = audioCtx.createOscillator();
        oscillator.type = 'sine'; // Wellenformtyp: 'sine', 'square', 'sawtooth', 'triangle'
        oscillator.frequency.setValueAtTime(440, audioCtx.currentTime); // Frequenz auf 440 Hz (A4) setzen

        // Erstelle einen GainNode für längere Signale
        const gainNode = audioCtx.createGain();
        gainNode.gain.setValueAtTime(0.25, audioCtx.currentTime); // Lautstärke niedrig setzen

        // Verknüpfe den Oszillator mit dem GainNode und diesen dann mit dem AudioContext-Zieldarstellung
        oscillator.connect(gainNode);
        gainNode.connect(audioCtx.destination);

        // Starte und stoppe den Oszillator nach einer kurzen Dauer
        oscillator.start();
        setTimeout(() => {
            oscillator.stop();
        }, 100); // 100 Millisekunden für ein sehr kurzes Signal
    }
}

