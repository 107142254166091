export const precisionToDecimals = (precision: number): number => {
    // Wandelt die Präzision in eine Ganzzahl um
    const precisionStr = precision.toString();
    const decimalIndex = precisionStr.indexOf('.');
    if (decimalIndex === -1) {
        return 0; // keine Nachkommastellen
    }
    return precisionStr.length - decimalIndex - 1;
}
/**
 * Konvertiert eine Zahl in das angegebene lokale Zahlenformat mit Option für trailing Nullen basierend auf der Tick-Größe.
 * @param amount Die zu formatierende Zahl.
 * @param locale Der lokale Code (z. B. 'de-DE', 'en-US').
 * @param options Zusätzliche Formatierungsoptionen.
 * @param tickSize (Optional) Bestimmt die Anzahl der Dezimalstellen für trailing Nullen.
 * @returns Die formatierte Zahl als String.
 */
export const formatNumberToLocale = (
    amount: number,
    locale: string,
    options?: Intl.NumberFormatOptions,
    tickSize?: number
): string => {
    let finalOptions = {...options};

    // Wenn eine Tick-Größe angegeben ist, bestimme die Dezimalstellen.
    if (tickSize !== undefined) {
        const tickSizeScientificNumberString = convertScientificToFullString(tickSize);
        const decimalPlaces = tickSizeScientificNumberString.split('.')[1]?.length || 0;
        finalOptions = {
            ...options,
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces
        };
    }

    return new Intl.NumberFormat(locale, finalOptions).format(amount);
}
export const convertScientificToFullString = (num: number): string => {
    // Ermittle die genaue Anzahl der Nachkommastellen aus der Exponentialdarstellung
    // in `1e-8` teilt das in ['1', '8'] und nimmt den 2. Teil (die Anzahl der Nullstellen)
    const exponentPart = num.toExponential().split('e-')[1];
    const decimals = exponentPart ? parseInt(exponentPart, 10) : 0;

    // Konvertiere die Zahl mit der ermittelten Anzahl Dezimalstellen
    return num.toFixed(decimals);
};

export const roundToTickSizeAsString = (value: number, tickSize: number, decimalPlaces = 10): string => {
    const roundedValue = Math.round(value / tickSize) * tickSize;
    // Sicherstellen, dass das Ergebnis auf die angegebene Anzahl Dezimalstellen formatiert wird
    return roundedValue.toFixed(decimalPlaces);
}

export const roundToTickSizeAsNumber = (value: number, tickSize: number, decimalPlaces = 10): number => {
    const roundedValue = Math.round(value / tickSize) * tickSize;
    // Konvertiert das Ergebnis in eine Zahl
    return parseFloat(roundedValue.toFixed(decimalPlaces));
}

export const calculatePixelWidthOfString = (numberString: string): number => {
    // Schritt 2: Berechne die Anzahl der Zeichen in der Zeichenkette
    const numCharacters = numberString.length;

    // Schritt 3: Multipliziere die Anzahl der Zeichen mit der Breite eines einzelnen Zeichens (8 Pixel)
    const pixelWidth = numCharacters * 8;

    return pixelWidth;
}

export function interpolateToTargetInterval(
    smallerData: any[],
    smallerInterval: number,
    targetInterval: number
): any[] {
    const result: any[] = [];
    const intervalMultiplier = targetInterval / smallerInterval;

    smallerData.sort((a, b) => a.openTime - b.openTime); // Sortiere nach Zeit

    for (let i = 0; i < smallerData.length; i += intervalMultiplier) {
        const window = smallerData.slice(i, i + intervalMultiplier);

        if (window.length > 0) { // Verarbeite auch unvollständige Gruppen
            const openTime = window[0].openTime;

            result.push({
                openTime,
                category: window[0].category,
                interval: targetInterval,
                open: window[0].open,
                close: window[window.length - 1].close,
                high: Math.max(...window.map((k: any) => k.high)),
                low: Math.min(...window.map((k: any) => k.low)),
                volume: window.reduce((vol: any, k: any) => vol + k.volume, 0),
                quoteAssetVolume: window.reduce((vol: any, k: any) => vol + k.quoteAssetVolume, 0),
            });
        }
    }

    // Prüfe, ob der Endzeitpunkt (`to`) fehlt und füge ihn hinzu
    const lastTimestamp = smallerData[smallerData.length - 1]?.openTime;
    const expectedLastTimestamp = smallerData[0]?.openTime + Math.floor(smallerData.length / intervalMultiplier) * targetInterval;

    if (lastTimestamp < expectedLastTimestamp) {
        console.warn(`Final timestamp missing. Adding an additional kline for the interval ending at ${expectedLastTimestamp}.`);

        const lastKline = smallerData[smallerData.length - 1];
        result.push({
            openTime: expectedLastTimestamp,
            category: lastKline.category,
            interval: targetInterval,
            open: lastKline.open,
            close: lastKline.close,
            high: lastKline.high,
            low: lastKline.low,
            volume: lastKline.volume,
            quoteAssetVolume: lastKline.quoteAssetVolume,
        });
    }

    console.log(`Interpolated ${result.length} klines to target interval.`);
    return result;
}
