import * as React from 'react';
import '../styles/scrollbar.css';
import '../styles/controls.css';
import IndicatorFilter from "./IndicatorFilter.tsx";
import Indicators from '../../shared/indicator';
import {useState} from "react";
import {Settings, Star} from "@mui/icons-material";
import {Indicator, IndicatorStorage} from "../../shared/interfaces/indicator.interface";
import LayoutCalculator from "../lib/LayoutCalculator";

const IndicatorTable: React.FC<{
    onSetIndicator: (indicators: IndicatorStorage[]) => void,
    onConfigureIndicator: (indicator: IndicatorStorage | null) => void,
    layoutCalculator: LayoutCalculator,
    selectedIndicators: IndicatorStorage[]
}> = ({onSetIndicator, layoutCalculator, onConfigureIndicator, selectedIndicators}) => {

    const [filter, setFilter] = React.useState('');
    const indicators = Object.keys(Indicators);
    const [storedItems, setStoredItems] = useState<IndicatorStorage[]>(() => selectedIndicators);
    const [activeConfigIndicator, setActiveConfigIndicator] = useState<IndicatorStorage | null>();

    const addIndicator = (indicator: Indicator) => {
        console.log(indicator);
        const newIndicatorStorage: IndicatorStorage = {
            isBoxed: indicator.isBoxed(),
            config: indicator.getConfig()
        };
        const updatedItems = [...storedItems, newIndicatorStorage];
        setStoredItems(updatedItems);
        onSetIndicator(updatedItems);
    };

    const removeIndicator = (indicator: IndicatorStorage) => {
        const updatedItems = storedItems.filter(item => item.config.id !== indicator.config.id);
        setStoredItems(updatedItems);
        onSetIndicator(updatedItems);
    }

    const toggleConfigPanel = (indicator: IndicatorStorage) => {
        // close panel
        if (activeConfigIndicator && activeConfigIndicator.config.id === indicator.config.id) {
            setActiveConfigIndicator(null);
            onConfigureIndicator(null);
            return;
        }
        // switch to other indicator
        onConfigureIndicator(indicator);
        setActiveConfigIndicator(indicator);
    };

    const filteredData = indicators
        .map((indicatorClassName: any) => {
            const IndicatorClass = (Indicators as any)[indicatorClassName];
            if (IndicatorClass) {

                const instance = new IndicatorClass();

                const searchArray = instance.getSearch();
                // Sicherstellen, dass searchArray ein Array ist
                if (Array.isArray(searchArray)) {
                    const searchTerms = filter.toLowerCase().split(' ').filter(term => term);
                    // Prüfen, ob alle Suchbegriffe in den Array-Strings vorkommen
                    const matches = searchTerms.every(term =>
                        searchArray.some((item: string) => item.toLowerCase().includes(term))
                    );
                    if (matches) {
                        return instance; // Rückgabe der Instanz selbst
                    }
                }
            }
            return null;
        })
        .filter(item => item !== null); // Ungültige Einträge entfernen

    return (
        <>
            <div className={`flex flex-col min-w-[${layoutCalculator.getSymbolMenuWidth()}px]`}>
                <div className={"my-2 px-2"}>
                    <input
                        className={`bg-ctaBackground px-2 py-1  focus:outline-none w-full`}
                        type="text"
                        placeholder="Search: Indicator name"
                        value={filter}
                        onChange={e => setFilter(e.target.value)}
                    />
                </div>
                {/* Markierte Liste */}
                <div className="p-0 overflow-y-scroll w-full custom-scrollbar max-h-[360px]">
                    {selectedIndicators.map((indicator) => {
                        const key = `${indicator.config.id}`;
                        return (
                            <div key={key}
                                 className="cursor-pointer bg-black hover:bg-elementBackground  text-primary hover:text-primaryHover shadow-md py-1 px-2 my-0 w-full">
                                <div
                                    className="flex justify-between items-center w-full ">

                                    <div className="w-3/5 text-left overflow-hidden text-ellipsis whitespace-nowrap"
                                         title={indicator.config.name}>{indicator.config.name}</div>
                                    <div className="w-1/5 text-center cursor-pointer"
                                         onClick={() => {
                                             toggleConfigPanel(indicator)
                                         }}
                                    >
                                        <Settings/>
                                    </div>

                                    <div className="w-1/5 text-center cursor-pointer"
                                         onClick={() => removeIndicator(indicator)}
                                    ><Star className="text-accent"/></div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className={`overflow-y-hidden w-full`}>
                    <div className={`flex flex-wrap`}>
                        <IndicatorFilter filteredData={filteredData} onAddIndicator={addIndicator}/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default IndicatorTable;