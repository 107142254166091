import React from 'react';
import SplashScreen from "./SplashScreen.tsx";

const HomePage: React.FC = () => {
    return (
        <div className={"max-h-screen w-full overflow-hidden"}>
            <SplashScreen/>
        </div>
    )
};


export default HomePage;

