import {Indicator, IndicatorStorage} from "../interfaces/indicator.interface.ts";
import {boxedIndicatorSlot, KlineData, Layout} from "../interfaces/interfaces";
import {ScaleLinear, ScaleTime, ZoomTransform} from "d3";
import {ResultData} from "./base/SimpleOverlayIndicator.ts";
import BoxedIndicator from "./base/BoxedIndicator.ts";

export class RSIIndicator extends BoxedIndicator implements Indicator {
    private cachedBullDivs: any[] | null = null;
    private cachedBearDivs: any[] | null = null;
    protected result: ResultData | null = null;
    public name: string = "Relative Strength";
    public search: string[] = ['RSI', 'Relative Strength Index'];

    constructor(inputConfig: IndicatorStorage, id?: string) {

        const defaultConfig = {
            id: id ? id : "",
            name: "Relative Strength",
            className: "",
            shortName: "RSI",
            search: ['RSI', 'Relative Strength'],
            sections: [
                {
                    id: "inputs",
                    title: "Inputs",
                    fields: [
                        {
                            id: "period",
                            label: "Period",
                            type: "number",
                            value: 14,
                            validations: [{
                                type: "minValue",
                                value: 1,
                                message: "Value must be greater or equal than 1"
                            }]
                        },
                        {
                            id: "height",
                            label: "Height",
                            type: "number",
                            value: 140,
                            validations: [{
                                type: "minValue",
                                value: 30,
                                message: "Value must be greater or equal than 30"
                            }]
                        }
                    ]
                },
                {
                    id: "styles",
                    title: "Styles",
                    fields: [
                        {
                            id: "color",
                            label: "Line Color",
                            type: "color",
                            value: "#4444ff",
                        }
                    ]
                }
            ]
        }
        const conf = inputConfig ? inputConfig.config : defaultConfig;

        super(conf, id);

    }

    public setInputData(data: KlineData[]) {
        this.data = data;
        this.clearCaches();
    }

    clearCaches() {
        this.cachedBullDivs = null;
        this.cachedBearDivs = null;
        this.result = null;
    }

    isBoxed() {
        return true;
    }

    getResults(): any {
        return this.result;
    }

    private getDivergences(rsiValues: ResultData | null) {
        // Wenn bereits gecached, dann gecachte Werte zurückgeben
        if (this.cachedBullDivs && this.cachedBearDivs) {
            return {bullDivs: this.cachedBullDivs, bearDivs: this.cachedBearDivs};
        }

        // Berechnung, falls kein Caching
        const {bullDivs, bearDivs} = this.calculateDivergences(rsiValues);

        // Caching der berechneten Werte
        this.cachedBullDivs = bullDivs;
        this.cachedBearDivs = bearDivs;

        return {bullDivs, bearDivs};
    }

    getSlot(slots: boxedIndicatorSlot[]): boxedIndicatorSlot {
        return slots.find(slot => slot.id === this.getId())!;
    }

    draw(visibleDataPoints: KlineData[], context: CanvasRenderingContext2D,
         xScale: ScaleTime<number, number>,
         yScale: ScaleLinear<number, number>,
         transform: ZoomTransform,
         layout: Layout
    ): void {
        const height = this.getHeight();
        const slot = this.getSlot(layout.boxedIndicatorSlots);
        context.strokeStyle = this.getColor();

        if (slot) {
            context.clearRect(slot.left, slot.top, slot.width, slot.height)
        } else {
            context.clearRect(0, context.canvas.height - height, context.canvas.width + window.screen.width, context.canvas.height);
        }

        const yOffset = slot && slot.top ? slot.top : context.canvas.height - height;
        const rsiValues = this.getResults() ? this.getResults() : this.calculate() as ResultData | null;
        // @ts-ignore
        const {bullDivs, bearDivs} = this.getDivergences(rsiValues);


        context.save();
        context.beginPath();

        let isFirstPoint = true;

        for (let i = 0; i < visibleDataPoints.length; i++) {
            if (rsiValues![i] !== null) {
                const xPos = transform.applyX(xScale(visibleDataPoints[i].openTime) + 1 / 3);
                const yPos = yOffset + (height - ((rsiValues![visibleDataPoints[i].openTime]! / 100) * height!)!)!;
                if (isFirstPoint) {
                    context.moveTo(xPos, yPos);
                    isFirstPoint = false;
                } else {
                    context.lineTo(xPos, yPos);
                }
            }
        }

        context.stroke();

        // //Funktion zum Zeichnen der Divergenzlinien
        const drawDivergences = (divs: any[], color: string) => {
            context.strokeStyle = color;
            context.lineWidth = 3;

            let count = 0;
            divs.forEach(div => {
                if (visibleDataPoints[visibleDataPoints.length - 1] && visibleDataPoints[0] &&
                    div.startTime < visibleDataPoints[visibleDataPoints.length - 1].openTime &&
                    div.startTime > visibleDataPoints[0].openTime &&
                    div.endTime < visibleDataPoints[visibleDataPoints.length - 1].openTime &&
                    div.endTime > visibleDataPoints[0].openTime
                ) {

                    context.beginPath();
                    const x1 = transform.applyX(xScale(div.startTime!)! + 1 / 3);

                    const y1 = yOffset + (height - ((div.rsiStartValue / 100) * height));
                    const x2 = transform.applyX(xScale(div.endTime!)! + 1 / 3);
                    const y2 = yOffset + (height - ((div.rsiEndValue / 100) * height));

                    context.moveTo(x1, y1);
                    context.lineTo(x2, y2);
                    context.stroke();
                    context.closePath();
                    count++;
                }
            });
        };

        // Bullish Divergences zeichnen
        drawDivergences(bullDivs, '#008e00');

        // Bearish Divergences zeichnen
        drawDivergences(bearDivs, '#8e0000');

        context.restore();
    }

    public calculate(): ResultData | null {
        const period = this.getPeriod();
        const closes = this.data.map(d => d.close);

        const rsi = this.calculateRSI(period, closes);

        this.result = this.data.reduce<ResultData>((acc, d, index) => {
            acc[d.openTime] = index >= period ? rsi[index - period] : null;
            return acc;
        }, {});

        return this.result;
    }

    calculateRSI(period: number, prices: number[]): number[] {
        const rsiValues: number[] = [];
        let gainSum = 0;
        let lossSum = 0;

        // Initial sums for the first 'period' values
        for (let i = 1; i <= period; i++) {
            const change = prices[i] - prices[i - 1];
            gainSum += Math.max(0, change);
            lossSum += Math.max(0, -change);
        }

        let avgGain = gainSum / period;
        let avgLoss = lossSum / period;
        rsiValues.push(avgLoss === 0 ? 100 : avgGain === 0 ? 0 : 100 - 100 / (1 + avgGain / avgLoss));

        // RSI values for the subsequent periods
        for (let i = period + 1; i < prices.length; i++) {
            const change = prices[i] - prices[i - 1];
            gainSum = (avgGain * (period - 1) + Math.max(0, change)) / period;
            lossSum = (avgLoss * (period - 1) + Math.max(0, -change)) / period;

            avgGain = gainSum;
            avgLoss = lossSum;

            rsiValues.push(avgLoss === 0 ? 100 : avgGain === 0 ? 0 : 100 - 100 / (1 + avgGain / avgLoss));
        }

        return rsiValues;
    }

    calculatePeaksAndTroughs(data: Array<{ value: number, index: number }>, range: number) {
        const peaks = [];
        const troughs = [];

        for (let i = range; i < data.length - range; i++) {
            const currentValue = data[i].value;

            const isPeak = data.slice(i - range, i + range + 1).every(point => currentValue >= point.value);
            const isTrough = data.slice(i - range, i + range + 1).every(point => currentValue <= point.value);

            if (isPeak) {
                peaks.push(data[i]);
            }

            if (isTrough) {
                troughs.push(data[i]);
            }
        }

        return {peaks, troughs};
    }

    calculateDivergences(rsiValues: ResultData | null) {
        const bullDivs = [];
        const bearDivs = [];
        const priceValues = this.data.map((d, index) => ({value: d.close, index}));

        let rsiArray: { value: number; index: number }[] = [];

        if (rsiValues !== null) {
            rsiArray = Object.entries(rsiValues)
                .map(([timestamp, value], index) => ({value, index}))
                .filter(item => item.value !== null);
        }

        // const rsiArray = rsiValues.map((d, index) => ({value: d.value, index}));

        const {peaks: pricePeaks, troughs: priceTroughs} = this.calculatePeaksAndTroughs(priceValues, 3);
        const {peaks: rsiPeaks, troughs: rsiTroughs} = this.calculatePeaksAndTroughs(rsiArray, 3);

        // Bullische Divergenzen erkennen
        for (let i = 1; i < priceTroughs.length; i++) {
            const previousPriceTrough = priceTroughs[i - 1];
            const currentPriceTrough = priceTroughs[i];
            const previousRsiTrough = rsiTroughs.find(trough => trough.index === previousPriceTrough.index);
            const currentRsiTrough = rsiTroughs.find(trough => trough.index === currentPriceTrough.index);

            if (previousRsiTrough && currentRsiTrough && currentPriceTrough.value < previousPriceTrough.value && currentRsiTrough.value > previousRsiTrough.value) {
                bullDivs.push({
                    startTime: this.data[previousPriceTrough.index].openTime,
                    endTime: this.data[currentPriceTrough.index].openTime,
                    startValue: previousPriceTrough.value,
                    endValue: currentPriceTrough.value,
                    rsiStartValue: previousRsiTrough.value,
                    rsiEndValue: currentRsiTrough.value,
                });
            }
        }

        // Bärische Divergenzen erkennen
        for (let i = 1; i < pricePeaks.length; i++) {
            const previousPricePeak = pricePeaks[i - 1];
            const currentPricePeak = pricePeaks[i];
            const previousRsiPeak = rsiPeaks.find(peak => peak.index === previousPricePeak.index);
            const currentRsiPeak = rsiPeaks.find(peak => peak.index === currentPricePeak.index);

            if (previousRsiPeak && currentRsiPeak && currentPricePeak.value > previousPricePeak.value && currentRsiPeak.value < previousRsiPeak.value) {
                bearDivs.push({
                    startTime: this.data[previousPricePeak.index].openTime,
                    endTime: this.data[currentPricePeak.index].openTime,
                    startValue: previousPricePeak.value,
                    endValue: currentPricePeak.value,
                    rsiStartValue: previousRsiPeak.value,
                    rsiEndValue: currentRsiPeak.value,
                });
            }
        }

        return {bullDivs, bearDivs};
    }


}