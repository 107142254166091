import * as React from 'react';
import '../styles/scrollbar.css';
import '../styles/controls.css';
import {useEffect, useState} from "react";
import {IndicatorStorage} from "../../shared/interfaces/indicator.interface";
import LayoutCalculator from "../lib/LayoutCalculator";
import {ConfigStructure} from "../../shared/types/indicator.config";
import {data} from "autoprefixer";

const IndicatorConfig: React.FC<{
    indicatorToConfigure: IndicatorStorage,
    onConfigureIndicator: (indicators: IndicatorStorage | null) => void,
    onSetIndicators: (indicators: IndicatorStorage[]) => void,
    selectedIndicators: IndicatorStorage[]
    layoutCalculator: LayoutCalculator,
}> = ({onSetIndicators, layoutCalculator, indicatorToConfigure, onConfigureIndicator, selectedIndicators}) => {

    const [config, setConfig] = useState<ConfigStructure>(indicatorToConfigure.config || {} as ConfigStructure);

    const handleInputChange = (
        sectionIndex: number,
        fieldIndex: number,
        value: any
    ) => {
        const newConfig = { ...config };
        newConfig.sections[sectionIndex].fields[fieldIndex].type === "number" && (value = parseFloat(value));

        newConfig.sections[sectionIndex].fields[fieldIndex].value = value;
        setConfig(newConfig);
        saveSettings({ ...indicatorToConfigure, config: newConfig });
    };

    const saveSettings = (indicator: IndicatorStorage) => {
        const updatedItems: IndicatorStorage[] = selectedIndicators.map(
            (item) =>
                item.config.id === indicator.config.id
                    ? { ...item, config: indicator.config }
                    : item
        );
        onSetIndicators(updatedItems);
    };

    useEffect(() => {
        setConfig(indicatorToConfigure.config)

    }, [indicatorToConfigure]);

    const panelRightPosition = layoutCalculator.getSymbolMenuWidth() + layoutCalculator.getLayout().canvas.margin.right;

    return (
        <>
            <div
                className={`relative right-[${panelRightPosition}] mx-0 px-0 my-2 py-4 min-w-96 max-w-fit  bg-black/85 `}>
                <form>
                    {indicatorToConfigure.config.sections.map((section, sectionIndex) => {
                        return (
                            <div key={sectionIndex} className="flex flex-col align-middle mx-2 mb-8 px-2">
                                <h3 className={`block mb-4 font-black text-xl `}>{section.title}</h3>
                                {section.fields.map((field, fieldIndex) => {
                                    return (
                                        <div key={fieldIndex} className="flex flex-col justify-between">
                                            <h4 className={`text-lg font-bold`}>{field.label}</h4>
                                            <div className="flex flex-col">
                                                <input type={field.type} value={field.value}
                                                       data-section-index={sectionIndex}
                                                       data-field-index={fieldIndex}
                                                       onChange={(e) => handleInputChange(sectionIndex, fieldIndex, e.target.value)}
                                                       className={`font-light  cursor-pointer bg-ctaBackground/20 px-2 py-0 focus:outline-none w-full`}/>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        );
                    })}
                </form>
            </div>
        </>
    );
}

export default IndicatorConfig;