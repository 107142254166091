import React, {useEffect, useState} from 'react';
import "../styles/SplashScreen.css";

const SplashScreen: React.FC = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setVisible(true);
        const timer = setTimeout(() => {
            setVisible(false);
        }, 3000); // 3 Sekunden einblenden
        return () => {
            clearTimeout(timer)
        }
    }, []);

    return (
        <div className={`splash-container select-none ${visible ? 'fade-in' : 'fade-out'}`}>
            <img src="/logo.webp" alt="Loading..." className="splashLogo"/>
            <p>Loading...</p>
        </div>
    );
};

export default SplashScreen;
