import React, {useEffect, useRef, useState} from 'react';
import {Layout, CandlestickChartProps, Execution, Signal, Trade} from '../../shared/interfaces/interfaces';
import CandlestickChart from "../lib/CandlestickChart";
import {IData} from "../../shared/models/data.model";
import {formatNumberToLocale} from "../../shared/helper/conversions";
import {IndicatorStorage} from "../../shared/interfaces/indicator.interface";
import LayoutCalculator from "../lib/LayoutCalculator";

const CandlestickD3Chart: React.FC<CandlestickChartProps & {
    buySignals: Trade[],
    sellSignals: Trade[],
    buyExecutions: Execution[],
    sellExecutions: Execution[],
    symbolMeta: IData,
    layout: LayoutCalculator,
    onCleanup: () => void,
    indicators: IndicatorStorage[],
    isLoading: boolean
}> = (props) => {

    const chartRef = useRef<CandlestickChart | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [onScreenData, setOnScreenData] = useState(undefined as any[] | undefined);

    useEffect(() => {
        if (!chartRef.current && canvasRef.current) {
            chartRef.current = new CandlestickChart(props);
        }
        console.log('chartRef.current: ', chartRef.current);
        if (chartRef.current && canvasRef.current && props.candlestickData && props.symbolMeta) {
            chartRef.current.componentDidMount(canvasRef);
            chartRef.current.init();

            chartRef.current.removeAllIndicator();
            props.indicators.map((value, index): void => {
                    if (chartRef.current) {

                        const indicator = chartRef.current.instantiateIndicator(value.config.className, value, value.config.id);
                        if (indicator) {

                            chartRef.current.addIndicator(indicator)
                            console.log(
                                "Indicator added: ", indicator.getConfig().sections[0], value.config);

                        }
                    }
                }
            );
            chartRef.current.updateAllIndicator();
            chartRef.current.calculateAllIndicator();
            chartRef.current.drawAll();
            setOnScreenData(chartRef.current.onScreenData);
            chartRef.current.setOnScreenData = (onScreenData) => {
                setOnScreenData(onScreenData);
            };
        }
    }, []);

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.removeAllIndicator();
            props.indicators.forEach(indicator => {
                if (chartRef.current) {
                    const indi = chartRef.current.instantiateIndicator(indicator.config.className, indicator, indicator.config.id!);
                    chartRef.current.addIndicator(indi);
                }
            });

            chartRef.current.updateAllIndicator();
            // console.log(chartRef.current.indicators);
            chartRef.current.calculateAllIndicator();
            chartRef.current.drawAll();
        }
        //setIsLoadingCandleStickData(true)

    }, [props.indicators]);

    useEffect(() => {
        if (chartRef.current) {
            document.body.style.overflow = 'hidden';
            // Sie rufen Ihre Chart-Update-Logik hier auf, um das neue Layout anzuwenden
            chartRef.current.setIsResizing();
            chartRef.current.setLayout(props.layout.getLayout());
            chartRef.current.componentDidUpdate(props);
        }

        return () => {
            document.body.style.overflow = 'auto'; // Zurücksetzen bei Komponentendemontage
        }
    }, [props.layout]); // Hiermit wird useEffect jedes Mal ausgeführt, wenn sich das layout ändert

    useEffect(() => {

        if (chartRef.current && canvasRef.current && props.candlestickData && props.symbolMeta) {
            chartRef.current.updateCandlestickData(props.candlestickData);
            chartRef.current.updateSymbolMeta(props.symbolMeta);
            chartRef.current.setIsResizing();
            chartRef.current.initStartXPosition();
            chartRef.current.componentDidMount(canvasRef);
            chartRef.current.rescaleYScale();
        }

        // Cleanup function
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
            props.onCleanup();
        };

    }, [props.candlestickData]); // on input data change

    const orderedKeys = ['color', 'name', 'period', 'value'];

    return (
        <div style={{position: 'relative'}}>
            {canvasRef && (
                <canvas
                    ref={canvasRef}
                    style={{backgroundColor: "rgb(0,0,0)", position: 'absolute', left: 0, top: 0}}
                />
            )}
            {props.isLoading && (
                <div
                    className={'font-black text-6xl family-sans absolute flex justify-center items-center w-full h-screen text-white/75'}>
                    Loading</div>
            )}
            <div style={{pointerEvents: 'none', position: 'absolute', top: 0, left: 0, color: 'white', padding: '5px'}}
                 className={'text-sm family-sans'}>

                {onScreenData! && onScreenData!.length > 0 &&
                    onScreenData!.map((d: any, index: number) => {
                        if (d.type === 'CurrentCandle') {
                            return (
                                <div
                                    key={index}
                                    className={`flex justify-between`}
                                    style={{
                                        color: d.candle.close < d.candle.open ?
                                            props.layout.getLayout().candle.downCandleInfoColor :
                                            props.layout.getLayout().candle.upCandleInfoColor,
                                    }}
                                >
                                    <div
                                        className={`min-w-20 mr-1`}>O: {formatNumberToLocale(d.candle.open, props.layout.getLayout().browserLanguage, undefined, props.symbolMeta.priceFilter.tickSize)}</div>
                                    <div
                                        className={`min-w-20 mr-1`}>H: {formatNumberToLocale(d.candle.high, props.layout.getLayout().browserLanguage, undefined, props.symbolMeta.priceFilter.tickSize)}</div>
                                    <div
                                        className={`min-w-20 mr-1`}>L: {formatNumberToLocale(d.candle.low, props.layout.getLayout().browserLanguage, undefined, props.symbolMeta.priceFilter.tickSize)}</div>
                                    <div
                                        className={`min-w-20 mr-1`}>C: {formatNumberToLocale(d.candle.close, props.layout.getLayout().browserLanguage, undefined, props.symbolMeta.priceFilter.tickSize)}</div>
                                    <div
                                        className={`min-w-20 mr-1`}>V: {formatNumberToLocale(d.candle.volume, props.layout.getLayout().browserLanguage, undefined, props.symbolMeta.priceFilter.tickSize)} {props.symbolMeta.baseCoin}</div>
                                    <div
                                        className={`min-w-20`}>QV: {formatNumberToLocale(d.candle.quoteAssetVolume, props.layout.getLayout().browserLanguage, undefined, props.symbolMeta.priceFilter.tickSize)} {props.symbolMeta.quoteCoin}</div>
                                </div>
                            )
                        } else {
                            return <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    marginBottom: '2px',
                                    color: d.color || 'inherit', // Setzen der Farbe hier
                                }}
                            >
                                {orderedKeys.map((key) => {
                                    const value = d[key];
                                    if (key === 'name' && value) return (
                                        <div key={`${index}-name`} className={'pr-1'}>{value}</div>);
                                    if (key === 'period' && value) return (
                                        <div key={`${index}-period`} className={'pr-1'}>({value})</div>);
                                    if (key === 'value' && !isNaN(value)) {
                                        return (
                                            <div key={`${index}-value`}>
                                                {formatNumberToLocale(value, props.layout.getLayout().browserLanguage, undefined, props.symbolMeta.priceFilter.tickSize)}
                                            </div>
                                        );
                                    }
                                    return null; // Fallback für nicht gefundene oder nicht verwendete Schlüssel
                                })}
                            </div>
                        }
                    })}
            </div>
        </div>
    );
}

export default CandlestickD3Chart;