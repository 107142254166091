import * as React from 'react';
import {useQuery} from 'react-query';
import {getAllSymbolsResponseData} from "../lib/ApiCall.ts";
import '../styles/scrollbar.css';
import SymbolFilter from "./SymbolFilter.tsx";
import LayoutCalculator from "../lib/LayoutCalculator";


const SymbolTable: React.FC<{ currentInterval: number ,
    layoutCalculator:LayoutCalculator}> =
    ({currentInterval,layoutCalculator}) => {
    const {data, error, isLoading} = useQuery('symbols', getAllSymbolsResponseData);
    const [filter, setFilter] = React.useState('');

    if (isLoading) return <div className={"text-center min-w-[360px] h-screen py-60"}>Loading...</div>;

    if (error && (error as Error).message) {
        return <div>Error fetching data: {(error as Error).message}</div>;
    }

    const filteredData = data?.data.filter((d: any) => {
        // Aufteilen der Suchbegriffe und in Kleinbuchstaben umwandeln
        const searchTerms = filter.toLowerCase().split(' ').filter(term => term);

        // Prüfen, ob jeder Suchbegriff in einem der Felder vorkommt
        return searchTerms.every(term =>
            d.symbol?.toLowerCase().includes(term) ||
            d.exchange?.toLowerCase().includes(term) ||
            d.category?.toLowerCase().includes(term)
        );
    });

    return (
        <>
            <div className={`flex flex-col min-w-[${layoutCalculator.getSymbolMenuWidth()}px]`}>
                <div className={"my-2 px-2"}>
                    <input
                        className={`bg-ctaBackground px-2 py-1  focus:outline-none w-full`}
                        type="text"
                        placeholder="Search: Symbol, Exchange, or Category..."
                        value={filter}
                        onChange={e => setFilter(e.target.value)}
                    />
                </div>

                <div className={`overflow-y-hidden w-full`}>
                    <div className={`flex flex-wrap`}>
                        <SymbolFilter currentInterval={currentInterval} filteredData={filteredData}/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SymbolTable;