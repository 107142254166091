import "stream-browserify";
import React, {useEffect, useMemo, useState} from 'react';
import '../styles/App.css';
import SplashScreen from "./SplashScreen.tsx";
import {useParams} from 'react-router-dom';
import {KlineData, Execution, Trade, Layout, boxedIndicatorSlot} from '../../shared/interfaces/interfaces';
import CandlestickD3Chart from "./CandleStickD3Chart.tsx";
import {IData} from "../../shared/models/data.model.ts";
import LayoutCalculator from "../lib/LayoutCalculator.ts";
import {getAllKlineResponseData} from "../lib/ApiCall.ts";
import {IndicatorStorage} from "../../shared/interfaces/indicator.interface";
import throttle from "lodash/throttle";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const DetailView: React.FC<{
    parentElementWidth: number,
    parentElementScreenSize: { width: number, height: number },
    isOpen: boolean,
    indicators: IndicatorStorage[]
}> =
    ({parentElementWidth, parentElementScreenSize, isOpen, indicators}) => {

        const {exchange, symbol, category, interval, strategy_id} = useParams<{
            exchange: string,
            symbol: string,
            category: string,
            interval: string,
            strategy_id?: string
        }>();

        console.log('Detailview render', parentElementWidth, parentElementScreenSize, isOpen, indicators);

        const layout = useMemo(() => {
            const throttled = throttle(() => {
                const calculator = new LayoutCalculator(parentElementScreenSize, isOpen);
                indicators.map((indicator, index) => {
                    let indiHeight = 0;
                    indicator.config.sections.forEach(section => {
                        section.fields.forEach(field => {
                            if (field.id === "height") {
                                if (typeof field.value === "number") {
                                    indiHeight = field.value;
                                }
                            }
                        });
                    });

                    const slot = {
                        id: indicator.config.id,
                        slot: index,
                        width: calculator.getLayout().canvas.width,
                        isBoxed: indicator.isBoxed,
                        height: indiHeight,
                        top: calculator.getLayout().chartBox.height - indiHeight + calculator.getLayout().dateBarAreaBox.height,
                        left: 0,
                    };
                    if (indicator.isBoxed) {
                        calculator.addBoxedIndicatorSlots(slot);
                    }
                    return indiHeight
                });
                return calculator;
            }, 200);
            return throttled();
        }, [parentElementWidth, parentElementScreenSize, isOpen, indicators]);

        const [klineData, setKlineData] = useState<KlineData[]>([]);
        const [symbolMeta, setSymbolMeta] = useState<IData>();
        const [trades, setTrades] = useState<Trade[]>([]);
        const [executions, setExecutions] = useState<Execution[]>([]);
        const [loading, setLoading] = useState<boolean>(true);
        const [error, setError] = useState<string | null>(null);
        const [showContent, setShowContent] = useState<boolean>(true);

        useEffect(() => {

            const fetchData = async () => {
                setLoading(true)
                try {

                    const klineResponse = await getAllKlineResponseData(
                        exchange,
                        symbol,
                        category,
                        interval
                    );

                    const {klineData, symbolMeta} = await klineResponse.json();

                    setKlineData(klineData);
                    setSymbolMeta(symbolMeta);

                    const tradesResponse = await fetch(
                        `${baseUrl}/trades?exchange=${exchange}&symbol=${symbol}&interval=${interval}&strategy_id=${strategy_id}`
                    );

                    if (!tradesResponse.ok) {
                        throw new Error('Fehler beim Abrufen der Trade-Daten');
                    }

                    const strategyData = await tradesResponse.json();

                    if (strategyData) {
                        const fetchedTrades: Trade[] = strategyData.trades;
                        setTrades(fetchedTrades);
                    }

                    const executionsResponse = await fetch(
                        `${baseUrl}/executions?exchange=${exchange}&symbol=${symbol}&interval=${interval}`
                    );

                    if (!executionsResponse.ok) {
                        throw new Error('Fehler beim Abrufen der Executions-Daten');
                    }

                    const excecutionsData = await executionsResponse.json();

                    const fetchedExecutions: Execution[] = excecutionsData;

                    setExecutions(fetchedExecutions);

                } catch (error) {
                    console.error('Fetch Error:', error); // Debugging-Log
                    setError((error as Error).message);
                    setLoading(false);
                } finally {
                    setLoading(false);
                }
            };
            console.log('DetailView mounted');

            fetchData();

            return () => {
                console.log(
                    'Cleaning up after unmounting DetailView component')
            }

        }, [exchange, symbol, category, interval, strategy_id]);

        const buyData = trades.filter(trade => trade.signal === 'buy');
        const sellData = trades.filter(trade => trade.signal === 'sell');

        const executionBuyData = executions ? executions.filter(execution => execution.signal === 'buy' && execution.signalOpenTimestamp) : [];
        const executionSellData = executions ? executions.filter(execution => execution.signal === 'sell' && execution.signalOpenTimestamp) : [];

        const handleCleanup = () => {
            console.log('Cleanup requested from child component');
        };

        if (!showContent) {
            return <><SplashScreen/></>;
        }

        if (error) {
            return <div>Fehler: {error}</div>;
        }

        if (!symbolMeta) {
            return (
                <div style={{width: layout.getLayout().canvas.width}}
                     className={'flex flex-col items-center justify-center'}>
                    <div>Loading Symbol Data</div>
                </div>
            );
        }

        if (showContent && klineData.length === 0) return (
            <div style={{width: layout.getLayout().canvas.width}}
                 className={'flex flex-col items-center justify-center'}>
                <div>
                    No Data for selected timeframe and symbol. choose another (higher) timeframe
                </div>
            </div>
        );

        return showContent && klineData.length > 0 ? (
            <div style={{width: layout.getLayout().canvas.width}}>
                <div id="candlestick-chart">
                    <CandlestickD3Chart
                        candlestickData={klineData}
                        buySignals={buyData}
                        sellSignals={sellData}
                        buyExecutions={executionBuyData}
                        sellExecutions={executionSellData}
                        symbolMeta={symbolMeta}
                        layout={layout}
                        onCleanup={handleCleanup}
                        indicators={indicators}
                        isLoading={loading}
                    />
                </div>
            </div>
        ) : null;

    };

export default DetailView;
