import React, { useEffect, useState } from 'react';
import DashboardStrategiesDetail from './DashboardStrategiesDetail.tsx';

export interface Strategy {
    strategyId: number;
    name: string;
    status: string;
}

interface DashboardStrategiesOverviewProps {
    strategies: Strategy[];
}

const DashboardStrategiesOverview: React.FC = ( ) => {
    const [data, setData] = useState<DashboardStrategiesOverviewProps>();

    useEffect(() => {
        // Fetch strategies from API and set them in state
        const fetchStrategies = async () => {
            try {
                const url = process.env.REACT_APP_API_BASE_URL;

                const response = await fetch( url +'/strategies');
                const data = await response.json();
                if (data.status ==='success') {
                    console.log(data);
                    setData(data);
                }
            } catch (error) {
                console.error('Error fetching strategies:', error);
            }
        };
        fetchStrategies();
    }, []);

    return (
        <div className={ "w-full flex flex-col items-center"}>
            {data && data.strategies.slice(0,10).map(s =>
                    <DashboardStrategiesDetail key={s.strategyId} strategyId={s.strategyId} name={s.name} />
                )
            }
        </div>
    )
};


export default DashboardStrategiesOverview;
