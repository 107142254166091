import {ScaleLinear, ScaleTime, ZoomTransform} from "d3";
import {KlineData} from "../../interfaces/interfaces";
import {Indicator, IndicatorStorage} from "../../interfaces/indicator.interface.ts";
import {ConfigStructure} from "../../types/indicator.config.ts";

export interface ResultData {
    [timestamp: number]: number | null;
}

export interface SimpleOverlayIndicatorConfig {
    period: number;
    color: string;
}

export default abstract class SimpleOverlayIndicator implements Indicator {
    protected data: KlineData[] = [];
    protected result: ResultData | null = null;
    protected config: ConfigStructure;

    constructor(inputConfig: ConfigStructure, id?: string) {
        inputConfig.className = this.constructor.name;
        inputConfig.id = id ? id :inputConfig.className + ':' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        this.config = inputConfig;
    }

    public abstract calculate(): ResultData | null;

    public abstract getResults(): ResultData | null;

    isBoxed(): boolean {
        return false;
    }

    getLongestPeriod(): number | null {
        let maxValue: number | null = null;

        this.config.sections.forEach(section => {
            section.fields.forEach(field => {
                if (field.id === "period") {
                    if (typeof field.value === "number") {
                        if (maxValue === null || field.value > maxValue) {
                            maxValue = field.value;
                        }
                    }
                }
            });
        });
        return maxValue;
    }

    getColor(): string | null {
        let color: string | null = null;
        this.config.sections.forEach(section => {
            section.fields.forEach(field => {
                if (field.id === "color") {
                    if (typeof field.value === "string") {
                        color = field.value;
                    }
                }
            });
        });
        return color;
    }

    getPeriod(): number | null {
        let period: number | null = null;
        this.config.sections.forEach(section => {
            section.fields.forEach(field => {
                if (field.id === "period") {
                    if (typeof field.value === "number") {
                        period = field.value;

                    }
                }
            });
        });
        return period;
    }

    getSearch(): string[] {
        return this.config.search;
    }

    public getResult(timestamp: number): number | null {
        return this.result ? this.result[timestamp] : null;
    }

    public getId(): string {
        return this.config.id;
    }

    public getConfig(): ConfigStructure {
        return this.config;
    }

    public clearCaches(): void {
        this.result = null;
    }

    public setInputData(data: KlineData[]) {
        this.data = data;
        this.clearCaches();
    }

    draw(visibleDataPoints: KlineData[], context: CanvasRenderingContext2D, xScale: ScaleTime<number, number>, yScale: ScaleLinear<number, number>, transform: ZoomTransform): void {

        this.result = this.getResults() ? this.getResults() : this.calculate() as ResultData | null;
        context.strokeStyle = this.getColor()!;
        context.lineWidth = 1.25 / transform.k;
        context.save();
        context.beginPath();

        if (this.result) {
            for (let i = 0; i < visibleDataPoints.length; i++) {

                const resultValue = this.result[visibleDataPoints[i].openTime];
                if (resultValue) {
                    const xPos = xScale(visibleDataPoints[i].openTime) + 1 / 3;
                    // @ts-ignore
                    const yPos = yScale(resultValue);
                    if (i === 0) {
                        context.moveTo(xPos, yPos);
                    } else {
                        context.lineTo(xPos, yPos);
                    }
                }
            }
            context.stroke();

        }
        context.restore();
    }
}