import * as React from 'react';
import '../styles/scrollbar.css';
import {useNavigate, useParams} from "react-router-dom";

interface TopBarProps {
    onIntervalChange: (interval: number) => void;
}

const TopBarChart: React.FC<TopBarProps> = ({onIntervalChange}) => {

    const navigate = useNavigate();
    const {exchange, symbol, category, strategyId} =
        useParams<{ exchange: string, symbol: string, category: string, strategyId?: string }>();

    const handleButtonClick = (interval: number) => {

        onIntervalChange(interval);

        if (exchange && symbol && category) { // Sicherstellen, dass die Parameter vorhanden sind
            navigate(`/details/${exchange}/${symbol}/${category}/${interval}/${strategyId}`);
        } else {
            console.error('Einige URL-Parameter fehlen');
        }
    };

    return (
        <>
            <div className={`flex flex-row w-full h-8 bg-blue-900/10 text-center`}>
                <div className={`flex flex-row w-full h-8  text-center`}>
                    <button className="px-2 border-r  border-r-blue-700/10  hover:bg-blue-900/20"
                            onClick={() => handleButtonClick(60000)}>1m
                    </button>
                    <button className="px-2 border-r  border-r-blue-700/10  hover:bg-blue-900/20"
                            onClick={() => handleButtonClick(300000)}>5m
                    </button>
                    <button className="px-2 border-r  border-r-blue-700/10  hover:bg-blue-900/20"
                            onClick={() => handleButtonClick(900000)}>15m
                    </button>
                    <button className="px-2 border-r border-r-blue-700/10 hover:bg-blue-900/20"
                            onClick={() => handleButtonClick(3600000)}>1h
                    </button>
                    <button className="px-2 border-r border-r-blue-700/10 hover:bg-blue-900/20"
                            onClick={() => handleButtonClick(86400000)}>1d
                    </button>
                </div>
            </div>
        </>
    );
}

export default TopBarChart;