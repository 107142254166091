import {HashRouter as Router, Routes, Route, Link} from 'react-router-dom';
import * as React from 'react';
import {createRoot} from 'react-dom/client';
import './client/styles/tailwind.css';
import DetailView from './client/components/DetailView.tsx'; // Korrigierter Import
import ErrorBoundary from './client/components/ErrorBoundary.tsx';
import {useEffect, useRef, useState} from "react";
import SymbolTable from "./client/components/SymbolTable.tsx"; // Neuen Button-Component importieren
import {QueryClient, QueryClientProvider} from 'react-query';
import Schema from '@mui/icons-material/Schema';
import {Home, Explore, ManageSearchOutlined, Fullscreen, FullscreenExit} from '@mui/icons-material';
import TopBarStrategies from "./client/components/TopBarChartStrategies.tsx";
import TopBarChart from "./client/components/TopBarChart.tsx";
import DashboardStrategiesOverview from "./client/components/DashboardStrategiesOverview.tsx";
import HomePage from "./client/components/HomePage.tsx";
import TopBarHome from "./client/components/TopBarHome.tsx";
import IndicatorTable from "./client/components/IndicatorTable.tsx";
import {IndicatorStorage} from "./shared/interfaces/indicator.interface";
import layoutCalculator from "./client/lib/LayoutCalculator";
import IndicatorConfig from "./client/components/IndicatorConfig";

const App: React.FC = () => {
    console.log('index')
    const layout = new layoutCalculator();
    const xMargins = layout.getLayout().canvas.margin.right;
    const queryClient = new QueryClient();
    const parentRef = useRef<HTMLDivElement | null>(null); // Typ spezifizieren
    const [childWidth, setChildWidth] = useState(0);
    const [screenSize, setScreenSize] = useState({width: 0, height: 0});
    const [isOpenSidebar, setIsOpenSidebar] = useState(false);
    const [isOpenSymbol, setIsOpenSymbol] = useState(false);
    const [isOpenIndicator, setIsOpenIndicator] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [interval, setInterval] = useState<number>(86400000);
    const [indicatorToConfigure, setIndicatorToConfigure] = useState<IndicatorStorage | null>(null);
    const [selectedIndicators, setSelectedIndicators] = useState<IndicatorStorage[]>(() => {
        return JSON.parse(localStorage.getItem('ChartRider.storedIndicator') || '[]');
    });

    const updateScreenSize = () => {
        if (parentRef.current) {
            const width = document.body.offsetWidth - xMargins;
            const height = document.body.offsetHeight;
            setScreenSize({width: width, height: height});
        }
    };

    const handleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen().then(() => {
                setIsFullscreen(true);
            }).catch(err => {
                console.error(`Fehler beim Umschalten in den Vollbildmodus: ${err.message} (${err.name})`);
            });
        } else {
            document.exitFullscreen().then(() => {
                setIsFullscreen(false);
            });
        }
    };

    const handleIntervalChange = (newInterval: number) => {
        setInterval(newInterval);
    };

    const toggleIsOpenSymbol = () => {
        if (!isOpenSymbol) {
            setIsOpenIndicator(false); // Schließen Sie den Indikator, wenn das Symbol geöffnet wird
        }
        setIsOpenSymbol(prevIsOpenSymbol => !prevIsOpenSymbol);
    }

    const toggleIsOpenIndicator = () => {
        if (!isOpenIndicator) {
            setIsOpenSymbol(false); // Schließen Sie das Symbol, wenn der Indikator geöffnet wird
        }
        setIsOpenIndicator(prevIsOpenIndicator => !prevIsOpenIndicator);
    }

    const handleSetIndicators = (indicators: IndicatorStorage[]) => {
        setSelectedIndicators(indicators);
    };

    const handleConfigureIndicator = (indicator: IndicatorStorage | null) => {
        setIndicatorToConfigure(indicator);
    };

    useEffect(() => {
        if (parentRef.current) {
            const width = document.body.offsetWidth - xMargins
            setChildWidth(width);
        }
    }, [childWidth]);

    useEffect(() => {
        // console.warn('selectedIndicators saved', selectedIndicators);
        localStorage.setItem('ChartRider.storedIndicator', JSON.stringify(selectedIndicators));
    }, [selectedIndicators, indicatorToConfigure]);

    useEffect(() => {
        if (!isOpenSymbol && !isOpenIndicator) {
            setIsOpenSidebar(false);
        } else {
            setIsOpenSidebar(true);
        }
    }, [isOpenSymbol, isOpenIndicator]);


    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(!!document.fullscreenElement);
        };
        updateScreenSize(); // Breite initial ermitteln
        document.addEventListener("fullscreenchange", handleFullscreenChange);
        window.addEventListener('resize', updateScreenSize);
        return () => {
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
            window.removeEventListener('resize', updateScreenSize);
        };
    }, []);
    console.log('render render render');
    return (
        <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <Router>
                    <div
                        className="flex flex-col select-none h-screen overflow-hidden bg-blue-900/10 text-white/75 font-sans">
                        <Routes>
                            <Route path="/details/:exchange/:symbol/:category/:interval/:strategyId?"
                                   element={<TopBarChart onIntervalChange={handleIntervalChange}/>}/>
                            <Route path="/"
                                   element={<TopBarHome/>}/>
                            <Route path="/strategies"
                                   element={<TopBarStrategies onIntervalChange={handleIntervalChange}/>}/>
                        </Routes>

                        <div className="flex flex-row h-screen  overflow-hidden">
                            <div
                                className={`flex flex-grow flex-row overflow-hidden max-h-screen custom-scrollbar`}
                                ref={parentRef}>
                                <Routes>
                                    <Route path="/" element={<HomePage/>}/>
                                    <Route path="/strategies" element={<DashboardStrategiesOverview/>}/>
                                    <Route path="/details/:exchange/:symbol/:category/:interval/:strategyId?"
                                           element={<DetailView indicators={selectedIndicators}
                                                                parentElementWidth={childWidth}
                                                                parentElementScreenSize={screenSize}
                                                                isOpen={isOpenSidebar}/>}
                                    />
                                </Routes>

                                {isOpenSymbol && !isOpenIndicator && (
                                    <>
                                        <SymbolTable layoutCalculator={layout} currentInterval={interval}/>
                                    </>
                                )}

                                {isOpenIndicator && !isOpenSymbol && (
                                    <>
                                        {
                                            indicatorToConfigure &&
                                            <IndicatorConfig layoutCalculator={layout}
                                                             onSetIndicators={handleSetIndicators}
                                                             indicatorToConfigure={indicatorToConfigure}
                                                             onConfigureIndicator={handleConfigureIndicator}
                                                             selectedIndicators={selectedIndicators}
                                            />
                                        }
                                        <IndicatorTable layoutCalculator={layout}
                                                        onSetIndicator={handleSetIndicators}
                                                        onConfigureIndicator={handleConfigureIndicator}
                                                        selectedIndicators={selectedIndicators}
                                        />
                                    </>
                                )}

                            </div>

                            <div className="block w-[48px] bg-blue-900/10">
                                <Link
                                    className="inline-block p-2 mx-0  bg-blue-500/10 hover:bg-blue-900/30 w-[48px] text-center"
                                    to="/"><Home/><span className="text-[9px]">Home</span></Link>

                                <a className="select-none cursor-pointer inline-block p-2 mx-0  bg-blue-500/10 hover:bg-blue-900/30 w-[48px] text-center"
                                   onClick={toggleIsOpenSymbol}><ManageSearchOutlined/><span
                                    className="text-[9px]">Symbols</span></a>

                                <a className="select-none cursor-pointer inline-block p-2 mx-0  bg-blue-500/10 hover:bg-blue-900/30 w-[48px] text-center"
                                   onClick={toggleIsOpenIndicator}><Explore/><span
                                    className="text-[9px]">Indicator</span></a>
                                <Link
                                    className="inline-block p-2 mx-0  bg-blue-500/10 hover:bg-blue-900/30 w-[48px] text-center"
                                    to="/strategies"><Schema/><span
                                    className="text-[9px]">Strategy</span></Link>

                                <a className="select-none cursor-pointer inline-block p-2 mx-0  bg-blue-500/10 hover:bg-blue-900/30 w-[48px] text-center"
                                   onClick={handleFullScreen}>{isFullscreen ?
                                    <span><FullscreenExit/><span
                                        className="text-[9px] leading-[0.6rem] p-0 m-0">Exit</span></span> :
                                    <span><Fullscreen/><span
                                        className="text-[9px] leading-[0.6rem] p-0 m-0">Full</span></span>}</a>
                            </div>
                        </div>
                    </div>
                </Router>
            </QueryClientProvider>
        </ErrorBoundary>
    );
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App/>);