import React, {useState} from 'react';
import {StarBorderOutlined} from "@mui/icons-material";
import { IndicatorStorage} from "../../shared/interfaces/indicator.interface";

const IndicatorFilter =
    ({filteredData, onAddIndicator}: { filteredData: any[], onAddIndicator: (indicator: any) => void }) => {

        const [storedItems, setStoredItems] = useState<IndicatorStorage[]>(() => {
            return JSON.parse(localStorage.getItem('ChartRider.storedIndicator') || '[]');
        });

        const addStorage = (key: IndicatorStorage) => {
            const updatedItems = [...filteredData, key];
            setStoredItems(updatedItems);
            localStorage.setItem('ChartRider.storedIndicator', JSON.stringify(updatedItems));
        };

        const unmarkedItems = filteredData.filter((d: any) => !storedItems.includes(d.name));

        const handleIndicatorClick = (instance: any) => {
            if (instance && instance.constructor.name) { // Sicherstellen, dass instance ein gültiges Objekt ist
                const data: IndicatorStorage = {
                    config: instance.config
                };
                addStorage(data);
                if (typeof onAddIndicator === 'function') {
                    onAddIndicator(instance);
                }
            } else {
                console.error("Instance is undefined or malformed", instance);
            }
        };


        return (
            <div className="flex flex-col space-y-2 w-full">
                {/* Unmarkierte, scrollbare Liste */}
                <div className="overflow-y-scroll w-full max-h-screen custom-scrollbar">
                    {unmarkedItems.map((indicator: any) => {
                        const key = `${indicator.config.id}`;
                        return (
                            <div key={key}
                                 className="bg-blue-900/10 hover:bg-blue-900/20 shadow-md py-1 px-2 my-0 w-full">
                                <div
                                    className="cursor-pointer  flex justify-between items-center w-full  text-white/50 hover:text-white/75">
                                    <span className="w-4/5 text-left overflow-hidden text-ellipsis whitespace-nowrap"
                                          title={indicator.config.id}>{indicator.config.name}</span>
                                    <span className="w-1/5 text-center cursor-pointer text-white/50"
                                          onClick={() => handleIndicatorClick(indicator)}>
                                    <StarBorderOutlined color="inherit"/>
                                </span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

export default IndicatorFilter;