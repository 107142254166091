import * as React from 'react';
import '../styles/scrollbar.css';
import {useNavigate, useParams} from "react-router-dom";

interface TopBarProps {
    onIntervalChange: (interval: number) => void;
}

const TopBarStrategies: React.FC<TopBarProps> = ({onIntervalChange}) => {

    // const navigate = useNavigate();
    // const {exchange, symbol, category, strategyId} =
    //     useParams<{ exchange: string, symbol: string, category: string, strategyId?: string }>();
    //
    // const handleButtonClick = (interval: number) => {
    //
    //     onIntervalChange(interval);
    //
    //     if (exchange && symbol && category) { // Sicherstellen, dass die Parameter vorhanden sind
    //         navigate(`/details/${exchange}/${symbol}/${category}/${interval}/${strategyId}`);
    //     } else {
    //         console.error('Einige URL-Parameter fehlen');
    //     }
    // };

    return (
        <>
            <div className={`flex flex-row w-full h-8 bg-blue-900/10 text-center`}>
                <div className={`flex flex-row w-full h-8  text-center`}>
                    Strategies Overview
                </div>
            </div>
        </>
    );
}

export default TopBarStrategies;