import axios from "axios";
const api_domain = process.env.REACT_APP_API_BASE_URL;

export const getAllSymbolsResponseData = () => {
    const endpoint = api_domain + '/exchange/info/';

    try {
        return axios.get(endpoint);
    } catch (error) {
        console.error('Error fetching data: ', error);
    }
}

export const getAllKlineResponseData = async (
    exchange: string | undefined,
    symbol: string | undefined,
    category: string | undefined,
    interval: string | undefined
) => {

    const klineResponse = await fetch(
        `${api_domain}/kline?exchange=${exchange}&symbol=${symbol}&category=${category}&interval=${interval}`
    );

    if (!klineResponse.ok) {
        throw new Error('Fehler beim Abrufen der Kline-Daten');
    }
    return klineResponse;

}


