import {Indicator, IndicatorStorage} from "../interfaces/indicator.interface.ts";
import SimpleOverlayIndicator, {ResultData} from "./base/SimpleOverlayIndicator.ts";


function calculateEMA(period: number, values: number[]): number[] {
    const k = 2 / (period + 1);
    const emaArray = new Array(values.length);
    emaArray[0] = values[0];

    for (let i = 1; i < values.length; i++) {
        emaArray[i] = values[i] * k + emaArray[i - 1] * (1 - k);
    }
    return emaArray;
}

export class DEMAIndicator extends SimpleOverlayIndicator implements Indicator {

    protected result: ResultData | null = null;

    constructor(inputConfig: IndicatorStorage, id?: string) {

        const defaultConfig = {
            id: id ? id : "",
            name: "Double EMA",
            className: "",
            shortName: "DEMA",
            search: ['DEMA', 'Double Exponential Moving Average', 'Double EMA'],
            sections: [
                {
                    id: "inputs",
                    title: "Inputs",
                    fields: [
                        {
                            id: "period",
                            label: "Period",
                            type: "number",
                            value: 9,
                            validations: [{
                                type: "minValue",
                                value: 1,
                                message: "Value must be greater or equal than 1"
                            }]
                        }
                    ]
                },
                {
                    id: "styles",
                    title: "Styles",
                    fields: [
                        {
                            id: "color",
                            label: "Line Color",
                            type: "color",
                            value: "#e0b310",
                        }
                    ]
                }
            ]
        }
        const conf = inputConfig ? inputConfig.config : defaultConfig;

        super(conf, id);
    }

    calculate(): ResultData | null {
        this.result = null;
        const period = this.getPeriod();

        if (!period) return null;

        if (this.data.length < period) {
            return null;
        }

        const demaResults: ResultData = {};
        const closes = this.data.map(d => d.close);
        const ema1 = calculateEMA(period, closes);
        const ema2 = calculateEMA(period, ema1.slice(period));

        for (let i = period; i < ema1.length; i++) {
            const demaValue = 2 * ema1[i] - ema2[i - period];
            demaResults[this.data[i].openTime] = demaValue;
        }

        this.result = demaResults;
        return demaResults;
    }


    getResults(): ResultData | null {
        return this.result;
    }

    isBoxed(): boolean {
        return false;
    }
}