import {OBVIndicator} from "./obv.ts";
import {RSIIndicator} from "./rsi.ts";
import {DEMAIndicator} from "./dema.ts";
import {MovingAverageIndicator} from "./ma.ts";

const Indicators = {
    MovingAverageIndicator,
    DEMAIndicator,
    RSIIndicator,
    OBVIndicator,
}

export default Indicators
export type {SimpleOverlayIndicatorConfig} from './base/SimpleOverlayIndicator'
export type {BoxedIndicatorConfig} from './base/BoxedIndicator'
